.mobile_headline{
  text-align: left;
  margin-top: -5px;
  padding: 0 5px 15px 0;
  color: $font;
  font-size: 1.5em;
  display: none;
  font-family: "Titillium-bold", sans-serif;
}
@media only screen and (max-width : 667px) {
  .mcwidget-overlay {
    display: none;
  }
}

.mcwidget-overlay {
  display: none;
}
.section-banner{
  display: none;
}
.single-article{
  display: table;
  ._h5{
    background-color: $font;
    display:table;
    text-align: left;
    padding: 2px 12px 2px 15px;
    margin-left: -10px;
    margin-bottom: 10px;
    color: white;
    height: 18px;
    font-size: 12px;
    font-family: 'Sarala-bold', sans-serif;
    text-shadow: $shadow-fix;
    text-transform: uppercase;
    position: relative;
  }
  ._h5.tab1{
      cursor: pointer;
      float: left;
      margin-left: 0px;
      margin-right: 20px;
      &:hover{
        color: #579400;
      }
    }
  ._h5.tab2{
      cursor: pointer;
      float: left;
      margin-right: 20px;
      &:hover{
        color: #579400;
      }
    }
  ._h5.tab2::before{
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 0;
    height: 0;
    top: 1px;
    left: -15px;
    border-bottom: 20px solid $font;
    //border-top: 20px solid transparent;
    border-left: 15px solid transparent;
    @media only screen and (max-width : 369px) {
	  visibility: hidden;
    }
  }
  ._h5::after{
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 0;
    height: 0;
    top:0;
    right: -15px;
    border-top: 20px solid $font;
    border-bottom: 20px solid transparent;
    border-right: 15px solid transparent;
  }
}

.bundle_teaser{
  background-color: whitesmoke;
  border: 2px solid $color;
  padding:10px;
  margin:10px 0 20px 0;
  .btn{
    margin: 20px auto 15px 0;
  }
}

//--CENTRAL PART -> PRODUKTINHALT
.center-content{
  margin: 10px 15px 0 15px;
  width: 770px;
  padding: 0;
  box-shadow: $box-shadow;
  background: white;
  padding-bottom: 10px;
  @media only screen and (max-width : 1400px) {
    width: 470px;
  }
  
  .btn{
    margin: 15px 0;
    min-width: 200px;
    padding: 0 15px;
    background-color: $color;
    font-size: 14px;
    font-family: "Sarala-bold", sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.25);
    &::after{
      background-color: $font;
    }
  }
}
.product-description-wrapper{
  //width: 800px;
  display: inline-block;
}
.product-description{
  border-top:2px solid $font;
  vertical-align: top;
  display: inline-block;
  margin: 0 15px;
  width: 770px;
  min-height: 200px;
  padding: 0;
  box-shadow: $box-shadow;
  background: white;
  padding-bottom: 10px;
  @media only screen and (max-width : 1400px) {
    width: 470px;
  }
  ._h5{
    margin-left: 0;
  }
  h1{
    padding:3px 10px;
    font-size: 1.6em;
    line-height: 1.2;
  }
  h3, h2{
    font-size: 1.5em;
    color: $font;
    padding: 0.25em 0;
    strong{
      font-family: 'Titillium-bold', sans-serif;
    }
  }
  .poweredby{
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Titillium-bold', sans-serif;
    color: grey;
  }
  p{
    margin:7px 0;
    @media only screen and (max-width : 1400px) {
      text-align: left !important;
    }
  }
  .btn{
    margin: 15px 0;
    min-width: 200px;
    padding: 0 15px;
    background-color: $color;
    font-size: 14px;
    font-family: "Sarala-bold", sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.25);
    &::after{
      background-color: $font;
    }
  }
  img{
    display: block;
    margin: 5px 0 10px 0;
  }
  ul{
    margin: 0 0 10px 20px;
    li{
      padding: 1px 0;
      text-transform: inherit;
      display: block;
      h1,h2,h3,h4,h5,p{
        display: inline-block;
        vertical-align: middle;
      }
      &::before {
        content: "";
        display: inline-block;
        margin-right: 7px;
        width: 6px;
        height: 6px;
        vertical-align: middle;
        background-color: $font;
      }
    }
  }
  ol{
    margin: 0 0 10px 20px;
    li{
      list-style: decimal;
    }
  }
  ul.ts-reviews-list, ul.ts-reviews-pagination{
    li::before {
        display: none;
    }
  }
  .icon{
    margin: 0 10px 0 0;
    background-size: 40px;
    background-position:center;
    width: 40px;
    height: 58px;
    display: inline-block;
    vertical-align: middle;
  }
  .cdcase{
    background: url('images/doc-icons/disc.png') no-repeat center;
    margin-bottom: 0;
  }
  .dnl{
    background: url('images/doc-icons/dnl.png') no-repeat center;
    margin-bottom: 0;
  }
  .txt, .pagetext, .text{
    background: url('images/doc-icons/txt.png') no-repeat center;
  }
  .zip{
    background: url('images/doc-icons/zip.png') no-repeat center;
  }
  .pdf{
    background: url('images/doc-icons/pdf.png') no-repeat center;
  }
  .doc, .docx{
    background: url('images/doc-icons/doc.png') no-repeat center;
  }
  .xls, .xlsx{
    background: url('images/doc-icons/xls.png') no-repeat center;
  }
  .mmp{
    background: url('images/doc-icons/mmp.png') no-repeat center;
  }
}



//--MAKE THIS PAGE RESPONSIVE


@media only screen and (max-width : 1024px) {
 .single-page {
   .faq{
     display: none;
   }
 }
 .mobile_headline{
   display: block;
 }
  .single-article{
    display: block;
    text-align: center;
  }
 .product-description-wrapper{
   width: 100%;
   display: block;
 }
 .center-content {
   text-align: left;
   float: none;
   display: block;
   margin: 0 0 15px;
   width:100%;
 }
 .product-description {
   text-align: left;
   float: none;
   display: block;
   margin: 0 0 15px;
   width:100%;
   h1{
     display: none;
   }
 }
  .left-sidebar{
    float: none;
    display: inline-block;
    margin-left: 10px;
  }
 .right-sidebar{
   float: none;
   display: inline-block;
   width: 280px;
 }
 .additional-left, .additional-right{
   display: none;
 }
   ._h5.tab2::before{
    top: 0px !important;
  }
}

@media only screen and (max-width : 667px) {
  .mobile_headline{
    margin-top: 0;
    padding: 10px 0 15px 0;
  }
  .product-description{
    .view{
      padding-top: 8px;
    }
    section {
      font-size: 15px;
     }
    .poweredby{
      font-size: 14px;
    }
  }

  .section-banner {
    img {
      width: 50%;
    }
  }
  .single-article{
    ._h5{
      margin-bottom: 0;
    }
    ._h5.tab2::before{
      border-bottom: 19px solid #3c424e !important;
    }
    ._h5::after{
      border-top: 19px solid #3c424e !important;
    }
  }
}

@media only screen and (max-width : 580px) {
 .left-sidebar{
   margin: 0 auto;
   width: 100%;
   height: 349px;
  .item{
    margin: 0 auto;
    width: 200px;
  }
 }
 #web-paypal{
   display: none;
 }
 .mobile_headline{
   font-size: 1.35em;
   line-height: 1.3;
 }

  #trusted-shops-mobile{
    position: absolute;
    top: 20px;
    right: 41px;
  }

 .right-sidebar{
   form > svg{
     width: 312px;
     height: 39px;
   }
   width: 100%;
   display: block;
   margin: 0 auto;
   float: none;
 }
 .icon-count{
   margin-top: 10px;
 }
}
.view{
  max-height: 100%;
  padding: 0 10px;
  overflow: visible;
}
.noncollapse{
  display: inline-block; 
  width: 100%; 
  height: 100% !important; 
  background: none;
}
.tooglecontent{
  width: 250px;
  margin: 10px auto 5px;
  border-top: 2px solid $color;
  display: none;
  i{
    display: table;
    width: 200px;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    padding: 7px 0 0;
    font-style: normal;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Sarala-bold", sans-serif;
    transition: $transition;
    &:hover{

    }
    svg{
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
      fill: #000;
    }
    span{
      display: inline-block;
      vertical-align: middle;
    }
  }
  .overview-more{
  }
  .overview-less{
    width: 205px;
    display: none;
  }
}

@media only screen and (max-width :1024px) {
  .view {
    overflow: hidden;
    height: 150px;
    transition: height 0.5s ease-in-out;
    position: relative;
    &::after{
      height: 100%;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      top: auto;
      width: 100%;
      background: -moz-linear-gradient(top,rgba(255,255,255,0) 60%,rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top,rgba(255,255,255,0) 60%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom,rgba(255,255,255,0) 60%,rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    }
  }
  .noncollapse::after{
	background: none;
  }
  .expand{
    height: auto;
    &::after{
      display: none;
    }
  }
  .overview-less{
    display: none;
  }
  .tooglecontent{
    display: block;
    padding-bottom: 1px;
    .overview-more{
      display: block;
    }
  }
}
.alert{
  background-color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  box-shadow: $box-shadow;
  margin: 0 0 15px 0;
  color: #fff;
}
.alert-danger{
  background-color:#ed1c24;
}
.alert-success{
  background-color: $color;
}
.banner{
  border-top:2px solid $font;
  overflow: hidden;
  text-align:center;
  box-shadow: $box-shadow;
  background: white;
  padding: 0 10px 5px 10px;
  margin-bottom: 15px;
  img{
	max-width: 100%;
	max-height: 100%;
  }
}
@media only screen and (max-width : 200px) {

    ._h5.tab2::before{
      display: none !important;
    }
}