@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #index{
    .intro {
      background-attachment: local !important;
      background-position: center center !important;
    }
    .left-category-sidebar{
      margin-top: -26px !important;
      .sidebar-category{
        height: 684px !important;
      }
    }
  }
  .page .listing .category-listing ._r-column .r-holder{
    margin: 0 0 20px 0 !important;
    top: auto !important;
  }
  //--bestellung_1
  .down-butns-za .bezahlen-box .btn,
  .total .pay-btns .btn{
    line-height: 2.8 !important;
  }
    //--payment gateway
  ._part .payment-logo svg{
    position: relative !important;
    width: 90px;
    height: 45px;
  }
}

