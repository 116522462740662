iframe[name="google_conversion_frame"]{
        position: absolute;
        height: 0;
        width: 0;
}
footer {
    float: left;
    background: #32383e;
    width: 100%;
    height: auto;
    text-align: center;
    border-bottom: 3px solid $color;
    color: white;
    margin-top: 90px;
    .menu-nav{
        padding:20px 0 25px;
    }
    .nav-list{
        text-align: left;
        padding-top: 20px;
        p{
            font-size: 12px;
        }
        h3{
            font-size: 14px;
            font-family: "Sarala-bold", sans-serif;
            a{
                text-decoration: none;
                color: #579400;
            }
        }
        @media only screen and (max-width : 667px) {
            padding-top: 5px;
            p{
                font-size: 10px;
            }
        }
		.blog-content{
			display: table-cell; 
			width: 50%;
			div{
				width: 92%;
				float: right;
			}
		}
    }
}
ul.footer-menu{
    width: 24.5%;
    vertical-align: top;
    display: inline-block;
    p{
        text-transform: uppercase;
        position: relative;
        margin-bottom: 5px;
        font-size: 15px;
        font-family: "Sarala-bold", sans-serif;
        text-shadow: $shadow-fix;
    }
    li{
        a{
        width:90%;
        display: inline-block;
        font-size: 14px;
        text-decoration: none;
        color: #fff;
        }
    }
    li:hover a{
        opacity: 1;
    }
    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        text-align: left;
    }
    &:nth-child(2) {
        padding-left: 50px;
        @media only screen and (max-width : 1400px) {
            padding-left: 40px;
        }
        @media only screen and (max-width : 980px) {
            padding-left: 20px;
        }
        @media only screen and (max-width : 795px) {
            padding-left: 0;
        }
    }
    &:nth-child(3) {
        padding-left: 70px;
        @media only screen and (max-width : 1400px) {
            padding-left: 58px;
        }
        @media only screen and (max-width : 980px) {
            padding-left: 37px;
            width: 29%;
        }
        @media only screen and (max-width : 810px) {
            padding-left: 0;
            width: 49%;
        }
    }
    &:nth-child(4) {
        padding-left: 80px;
        @media only screen and (max-width : 1400px) {
            padding-left: 95px;
        }
        @media only screen and (max-width : 980px) {
            width: 20%;
            padding-left: 20px;
        }
        @media only screen and (max-width : 810px) {
            padding-left: 0;
            width: 49%;
        }
    }
}



.bekannt-box{
    width: 100%;
    background-color: white;
    padding: 10px 0 15px;
    @media only screen and (max-width : 980px) {
        display: none;
    }
    p{
        text-align: center;
        background-color: white;
        color: $font;
        font-size: 15px;
        padding: 0 0 10px;
        margin: 0 auto;
        text-transform: uppercase;
        font-family: "Sarala-bold", sans-serif;
        position: relative;
        @media only screen and (max-width : 980px) {
            display: none;
        }
    }
    ul{
        text-align: center;
        li{
            width: 24.5%;
            display: inline-block;
            vertical-align: middle;
            background-size: auto 70px;
            background-repeat: no-repeat;
            height: 70px;
        }
        .bild{
            background-image: url("images/footer/bild-de.svg");
            background-position: left;
        }
        .computerwoche{
            background-position:left 50px center;
            background-image: url("images/footer/computerwoche.svg");
            @media only screen and (max-width : 1400px) {
                background-position: left 20px center;
            }
        }
        .drweb{
            background-position: right 45px center;
            background-image: url("images/footer/drweb-de.svg");
            @media only screen and (max-width : 1400px) {
                background-position: right 20px center;
            }
        }
        .startups{
            background-position: right;
            background-image: url("images/footer/deutsche-startups.svg");
        }
    }
}

.zahlung-verifiziert-box{
    border-bottom: 1px solid rgba(255,255,255,0.25);
    padding: 25px 0 20px;
    @media only screen and (max-width : 980px) {
            border-top: 0;
            background-color: #fff;
            color: $color_2;
        a{
            color: $color_2 !important;
        }
    }
    ul{
        li{
            display: inline-block;
            vertical-align: top;

            &:nth-of-type(1){
                width: 24.5%;
                text-align: left;
            }
            &:nth-of-type(2){
                width: 49.5%;
                text-align: center;
            }
            &:nth-of-type(3){
                width: 25%;
                text-align: right;
            }
            @media only screen and (max-width : 1400px) {
                &:nth-of-type(1){
                    width: 24.5%;
                }
                &:nth-of-type(2){
                    width: 49.5%;
                }
                &:nth-of-type(3){
                    width: 25%;
                }
            }
            .method{
                text-transform: uppercase;
                font-size: 15px;
                padding-bottom: 5px;
                font-family: "Sarala-bold", sans-serif;
                text-shadow: $shadow-fix;
                margin-bottom: 10px;
              @media only screen and (max-width : 980px) {
                text-shadow: none;
              }
            }
            ._kservice{
                width: 140px;
                text-align: left;
                margin: 10px 0;
                ._mt{
                    font-size: 15px;
                }
                a{
                    color: #fff;
                    text-decoration: none;
                    &:hover{
                        color: $color;
                    }
                }
                span{
                    display: block;
                    font-size: 12px;
                }
            }
            a.trusted, #custom-trustbadge{
                vertical-align: top;
                display: inline-block;
            }
            #custom-trustbadge{
                margin-left: 10px;
                width:176px;
                height:88px;
                overflow: hidden;
                @media only screen and (max-width : 420px) {
                    display: block;
                    margin: 5px auto 0;
                }
            }
        }
    }
    .payments{
        display: block;
        margin: 0 0 0 auto;
                svg{
                    margin: 0;
                    display: inline-block;
                    width: 58px;
                    height: 35px;
                    padding: 3px 2px;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                    background-color: whitesmoke;
                }
        @media only screen and (max-width : 980px) {
            margin: 0 auto;
        }
    }
}
.sitemap-box{
    padding: 45px 0 20px;
    width: 100%;
    font-size: 14px;
    .l_side{
        width: 100%;
        text-align: center;
    }
    ul{
        width: 50%;
        float: left;
        text-align: left;
        &:nth-of-type(1){
            li:first-of-type::after {
                content: "Sitemap aller Produkte";
                position: absolute;
                left: 0;
                top: -28px;
                text-shadow: $shadow-fix;
                font-family: "Sarala", sans-serif;
                font-size: 13px;
                text-transform: uppercase;
                text-align: left;
                width: 190px;
            }
        }
        &:nth-of-type(2){
            text-align: right;
            li:last-of-type::after {
                content: "Sitemap aller Themen";
                position: absolute;
                right: 0;
                top: -28px;
                text-shadow: $shadow-fix;
                font-family: "Sarala", sans-serif;
                font-size: 13px;
                text-transform: uppercase;
                width: 190px;
              }
           }
        li{
            display: inline-block;
            margin:0 2px 10px 0;
            position: relative;
            background-color: rgba(255, 255, 255, 0.1);
            a{
                display: inline-block;
                color: white;
                text-decoration: none;
                padding: 1px 4px 1px 5px;
                z-index: 1;
                position: relative;
                &::after{
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    background-color: $color;
                    transform-origin: left center 0;
                    z-index: -1;
                    transition: width 0.35s ease;
                }
                &:hover::after{
                    width: 100%;
                }
            }
        }
    }
}

.copyright-box{
    width: 100%;
    padding: 15px 0;
    display: table;
    .copyright{
        display: table-cell;
        vertical-align: bottom;
        font-size: 12px;
        text-align: left;
        span{
            opacity: 0.65;
        }
    }
    .folgen-uns{
        text-align: right;
        display: table-cell;
        vertical-align: bottom;
        a{
            line-height: 2;
            height: 35px;
            text-align: center;
            display: inline-block;
            width: 75px;
            position: relative;
            background-color: rgba(255, 255, 255, 0.1);
            z-index: 1;
            svg{
                transition: $transition;
                fill: $color;
                vertical-align: middle;
                width: 18px;
                height: 18px;
                display: inline-block;
            }
            &:first-of-type{
                margin-right: 5px;
                &::after{
                    background-color: #3b5998;
                }
            }
            &:last-of-type{
                &::after{
                    background-color: #00aced;
                }
            }
            &::after{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                transform-origin: left center 0;
                z-index: -1;
                transition: width 0.35s ease;
            }
            &:hover{
                svg{
                    fill: #fff;
                }
                &::after {
                  width: 100%;
                }
            }
        }
    }
}


@media only screen and (max-width : 1400px) {
    .sitemap-box {
        ul li{
            margin: 0 1px 10px 0;
             a {
            padding: 1px 3px;
            font-size: 12px;
            }
        }
    }
}
@media only screen and (max-width : 980px) {
    .zahlung-verifiziert-box {
        padding: 20px 0;
        ul {
            text-align: center;
            li {
                &:first-of-type{
                    padding-left: 20px;
                    width: 30%;
                    margin-bottom: 20px;
                }
                &:nth-child(2){
                    width: 69%;
                    text-align: right;
                    padding-right: 20px;
                }
                &:last-of-type{
                   display: block;
                   text-align: center;
                   width: 100%;
                }
            }
        }
    }
    .sitemap-box {
        display: none;
    }
    .blog-content {
        display: none !important;
    }
    .copyright-box{
        padding: 10px 0;
        .copyright{
            vertical-align: middle;
        }
    }
}

@media only screen and (max-width : 810px) {
    ul.footer-menu {
        width: 49%;
        padding: 0 2% !important;
        &:nth-child(1), &:nth-child(2) {
            margin-bottom: 20px;
        }
        &:nth-child(3), &:nth-child(4) {
            text-align: left;
            li a::after{
                display: none;
            }
            li a::before{
                content: "";
                display: inline-block;
                margin-top: 0;
                margin-right: 6px;
                width: 5px;
                height: 5px;
                vertical-align: middle;
                background-color: $color;
            }
        }
    }
    .bekannt-box ul li {
        width: 49.5%;
    }
}
@media only screen and (max-width : 667px) {
    footer {
        margin-top: 35px;
    }
    .zahlung-verifiziert-box {
        padding: 20px 2%;
        width: 94%;
        margin: 0 auto;
        ul {
            li {
                text-align: center;
                &:first-of-type {
                    padding-left: 0;
                    width: 100%;
                    margin-bottom: 10px;
                    text-align: center;
                }
                &:nth-child(2) {
                    width: 100%;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .method{
                    margin-bottom: 3px;
                }
                ._kservice{
                    text-align: center;
                    margin: 0 auto 5px;
                }
            }
        }
    }
    .copyright-box{
        .copyright{
            display: block;
            width: 98%;
            text-align: center;
            margin-bottom: 15px;
            span{
                display: block;
            }
        }
        .folgen-uns {
            text-align: center;
            display: block;
        }
    }
}

@media only screen and (max-width : 460px) {
    footer{
        margin-top: 20px;
        .menu-nav{
           padding: 15px 0;
        }
    }
    .pre_headline{
        padding: 10px 5px 0;
    }
    .bekannt-box ul li {
        width: 49%;
        background-size: auto 50px;
        height: 50px;
    }
    .sitemap-box {
        .m_side {
            display: table;
            width:90%;
            margin: 0 auto;
        }
    }
    ul.footer-menu {
        width: 49%;
        margin-bottom: 5px !important;
        li{
            padding: 0 0;
            a{
               font-size: 12px;
                &::before{
                    display: none !important;
                }
            }
        }
        p{
            font-size: 13px;
            margin-bottom: 3px;
        }
    }
}

/*cookiebar*/

.cookie-notice-container {
    padding: 10px 5px 5px 20px;
    border-top: 1px solid #ccc;
    float: left;
    width: 100%;
}


#cn-accept-cookie {
    color: #579400;
    font-size: 20px;
    float: right;
    /*margin: 10px 10px 0 10px;*/
	text-decoration: none;
}

#cn-accept-cookie:hover {
    color: #001743;
}

#cookie-notice {
	background-color: white;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 2147483635;
}

#cookie-notice #cn-notice-text .small-notice {
 /*   font-size: 11px;
    line-height: 18px;*/
}
