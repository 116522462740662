.right-page-box {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding: 15px 20px 20px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    table {
        width: 100%;
    }
    p {
        margin: 10px 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
    //TW0 MAIN BREAKING POINTS
    @media only screen and (max-width: 1400px) {
        // width: 700px;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
    @media only screen and (max-width: 667px) {
        box-shadow: none;
        width: 104% !important;
        margin-left: -2% !important;
        padding-bottom: 40px;
    }
}

.page {
    .breadcrumb {
        padding: 15px 0;
        @media only screen and (max-width: 667px) {
            display: none;
        }
    }
}

.page-content {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding: 0 3% 30px;
    @media only screen and (max-width: 667px) {
        width: 104%;
        margin-left: -2%;
        box-shadow: none;
        //--footer width
        margin-bottom: -35px;
    }
    @media only screen and (max-width: 460px) {
        //--footer width
        margin-bottom: -20px;
    }
    p {
        margin: 8px 0;
    }
    h2,
    h3,
    h4 {
        padding-top: 0;
        padding-bottom: 5px;
    }
    hr {
        margin: 12px 0;
        border: 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ffffff;
    }
    dt {
        font-family: "Sarala-bold", sans-serif;
    }
    ul {
        margin-left: 10px;
        &.hooklist {
            list-style-type: none;
            margin: 0 20px;
            padding: 0 0 20px;
            li {
                background-image: url("/img/green_hook.png");
                background-position: 0 7px;
                background-repeat: no-repeat;
                background-size: 15px auto;
                background-color: none;
                line-height: 25px;
                padding-left: 25px;
                &::before {
                    background: none;
                    content: none;
                }
            }
        }
        li {
            padding: 2px 0;
            span.listitemwrapper {
                display: inline-block;
                width: 90%;
            }
            &::before {
                width: 5px;
                height: 5px;
                background: $font;
                display: inline-block;
                margin: 9px 6px 2px 0;
                content: "";
                vertical-align: top;
            }
        }
    }
    .customerreviews {
        table{
            border-spacing: 7px 20px;
            border-collapse: unset;
            td {
                padding: 5px;
                .order-info{
                    font-size: small;
                }
                .stars {
                    color: gold;
                }
                width: 50%;
            }
        }
        @media only screen and (max-width: 980px) {
            width: 100%;
            display: table;
        }
    }
    blockquote {
        position: relative;
        margin: 30px auto 30px 7%;
        width: 90%;
        padding: 0 0 0 10px;
        font-style: italic;
        border-left: 2px solid $font;
        font-size: 18px;
        @media only screen and (max-width: 667px) {
            width: 80%;
            margin-left: 15%;
        }
        .muted,
        cite {
            color: #696969;
            font-size: 14px;
            display: block;
            margin-top: 5px;
            &::before {
                content: "\2014 \2009";
            }
        }
        &::before {
            position: absolute;
            font-family: Georgia, serif;
            color: $color;
            font-size: 6em;
            line-height: 0.1em;
            z-index: 1;
            top: 10px;
            left: -60px;
            content: "\201C";
        }
    }
    blockquote.pull-right {
        text-align: right;
        border-left: 0;
        border-right: 2px solid $font;
        margin: 30px 7% 30px auto;
        padding: 0 10px 0 0;
        @media only screen and (max-width: 667px) {
            width: 80%;
            margin-right: 17%;
        }
        &::before {
            right: -60px;
        }
    }
}

.row-fluid {
    width: 100%;
    .margin-bottom {
        margin-bottom: 20px !important;
    }
    .span4 {
        width: 31%;
        margin: 0 2% 0 0;
        display: inline-block;
        vertical-align: top;
        @media only screen and (max-width: 910px) {
            width: 47%;
        }
        @media only screen and (max-width: 667px) {
            width: 97%;
        }
    }
    .span8 {
        width: 64%;
        margin: 0 2% 0 0;
        display: inline-block;
        vertical-align: top;
        @media only screen and (max-width: 910px) {
            width: 47%;
        }
        @media only screen and (max-width: 667px) {
            width: 97%;
        }
    }
}

.mediadaten-werbungen {
    position: relative;
    z-index: 1;
    h2 {
        font-size: 1.8em;
    }
    .facts {
        width: 100%;
        display: inline-block;
        margin: 10px 0 20px;
        padding: 10px 0;
        text-align: center;
        h2 {
            font-size: 2em;
            padding-bottom: 0;
        }
        p {
            font-size: 15px;
            display: inline-block;
            color: $font;
            width: 25%;
            margin: 15px auto;
            vertical-align: top;
            padding: 2px 0.5%;
            text-align: center;
            @media only screen and (max-width: 980px) {
                width: 40%;
            }
            @media only screen and (max-width: 667px) {
                width: 95%;
            }
            span {
                font-family: "Titillium-bold", sans-serif;
                font-size: 20px;
                text-transform: uppercase;
            }
        }
        .icon_in {
            font-style: normal;
            color: white;
            background-color: $font;
            display: block;
            border-radius: 100px;
            background-size: auto 30px;
            margin: 20px auto;
            width: 130px;
            height: 130px;
            position: relative;
            padding: 18px 0 0 0;
            font-family: "Titillium-bold", sans-serif;
            font-size: 3em;
            background-repeat: no-repeat;
            background-position: center;
            span {
                margin-top: -5px;
                display: block;
                font-size: 16px;
            }
            &::before {
                position: absolute;
                right: 0;
                bottom: 0;
                content: '';
                left: -7px;
                top: -7px;
                width: 108%;
                height: 108%;
                border-radius: 100px;
                border: 2px solid $color;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                width: 0;
                height: 0;
                z-index: -1;
                top: 94%;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-top: 25px solid $color;
            }
        }
    }
    ul {
        margin-left: 50px;
    }
    img {
        max-width: 800px;
        padding: 8px;
        margin: 5px auto 50px;
        display: table;
        position: relative;
        width: 70%;
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        &:hover {
            box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.35);
        }
    }
    .btn {
        height: 60px;
        max-width: 800px;
        display: block;
        font-size: 18px;
        font-family: "Titillium-bold", sans-serif;
        margin-bottom: 50px;
        line-height: 3.2;
        span {
            margin-right: 20px;
            @media only screen and (max-width: 667px) {
                margin-right: 0;
            }
        }
    }
    .contact {
        margin: 30px auto;
        border: 1px solid #ccc;
        display: table;
        padding: 20px 30px 30px;
        background-color: whitesmoke;
        @media only screen and (max-width: 667px) {
            padding: 0;
            background-color: transparent;
            border: 0;
        }
        p {
            margin: 5px 0;
            svg {
                margin-right: 10px;
                fill: $color;
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
            a {
                color: $font;
                &:hover {
                    color: $color;
                }
            }
        }
    }
}

//--PARTNERPROGRAMM
.table-content {
    overflow-x: auto;
}

.table {
    margin: 30px 0;
    min-width: 600px;
    tr {
        td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
        }
        td:first-of-type {
            width: 250px;
            background-color: $color;
            color: white;
        }
    }
    th {
        text-transform: uppercase;
        border: 1px solid #fff;
        background-color: $font;
        color: white;
        padding: 10px;
    }
    @media only screen and (max-width: 667px) {
        font-size: 14px;
        tr {
            td {
                padding: 5px;
            }
            td:first-of-type {
                width: 100px;
            }
        }
    }
}

//DATENSCHUTZ, AGB
.agb-ds {
    h3 {
        span {
            background: $color;
            color: #fff;
            display: inline-block;
            margin: 15px 1% 0 0;
            text-align: center;
            padding: 2px 10px 3px 12px;
            border-radius: 2px;
        }
    }
}

//--DATENSCHUTZGENERATOR, --IMPRESSUMGENERATOR
.r_section {
    padding: 15px;
    background-color: #daf5b4;
    display: inline-block;
    width: 48%;
    float: right;
}

iframe._dsgenerator {
    width: 50%;
    height: 830px;
    border: none;
}

@media only screen and (max-width: 667px) {
    iframe._dsgenerator {
        width: 100%;
        height: 500px;
    }
    .r_section {
        margin: 20px 0;
        float: none;
        width: 100%;
    }
}

//--.END
#sitemap {
    h1 {
        line-height: 1;
    }
    .listing_words {
        margin: 0 0 0 -10px;
        border-top: 1px solid #ccc;
        padding-top: 10px;
        li {
            display: inline-block;
            margin: 5px 6px;
            &::before {
                display: none;
            }
            a {
                display: inline-block;
                padding: 5px 10px;
                background: whitesmoke;
                border-radius: 5px;
                border: 1px solid #ddd;
                text-decoration: none;
                color: $font;
                position: relative;
                z-index: 1;
                overflow: hidden;
                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    transform-origin: left center 0;
                    z-index: -1;
                    background-color: $color;
                    transition: width 0.35s ease;
                }
                &:hover {
                    color: #fff;
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
        @media only screen and (max-width: 667px) {
            li {
                display: block;
                margin: 0;
                a {
                    padding: 1px 0;
                    display: block;
                    background-color: transparent;
                    border: none;
                    color: $link;
                    text-decoration: underline;
                    &::after {
                        display: none;
                    }
                    &:hover {
                        color: $color;
                    }
                }
            }
        }
    }
    .letters {
        display: block;
        margin: 20px 0 15px;
        a {
            display: inline-block;
            font-size: 14px;
            background-color: $font;
            padding: 6px 8px;
            text-decoration: none;
            color: #fff;
            position: relative;
            z-index: 2;
            &::after {
                background-color: $color;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                transform-origin: left center 0;
                z-index: -1;
                transition: $transition;
            }
            &:hover::after {
                width: 100%;
            }
        }
    }
}

#error {
    word-break: break-word;
    .check-it {
        text-align: center;
        padding-bottom: 30px;
        div {
            display: inline-block;
            width: 310px;
            background-color: $font;
            padding: 20px 0;
            margin: 20px 20px;
            position: relative;
            z-index: 1;
            &::after {
                background-color: $color;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                transform-origin: left center 0;
                z-index: -1;
                transition: $transition;
            }
            &:hover::after {
                width: 100%;
            }
            a {
                font-family: "Titillium-bold", sans-serif;
                text-transform: uppercase;
                display: block;
                text-decoration: none;
                color: #fff;
            }
            svg {
                fill: #fff;
                text-transform: uppercase;
                width: 100px;
            }
            span {
                padding-top: 10px;
                display: block;
            }
            @media only screen and (max-width: 800px) {
                display: block;
                margin: 20px auto;
                width: 300px;
            }
        }
    }
    form {
        margin: 20px auto 50px;
        text-align: center;
        input {
            padding: 10px 10px;
            width: 500px;
        }
        button {
            padding: 12px;
            width: 160px;
            background-color: $color;
            border: none;
            color: #fff;
            text-transform: uppercase;
            font-family: "Sarala-bold", sans-serif;
            position: relative;
            z-index: 1;
            cursor: pointer;
            &::after {
                background-color: $font;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                transform-origin: left center 0;
                z-index: -1;
                transition: $transition;
            }
            &:hover::after {
                width: 100%;
            }
        }
        @media only screen and (max-width: 800px) {
            margin-bottom: 20px;
            input {
                width: 100%;
            }
            button {
                margin-top: 5px;
                width: 200px;
            }
        }
    }
}