//--RIGHT SIDEBAR -> PRODUCT BUY
.right-sidebar{
  width: 280px;
  height: auto;
  display: inline-block;
  vertical-align: top;
  float: right;
  position: relative;
  padding-bottom: 1px;
  .product-buy{
    padding:10px;

    background-color: #fff;
    color: $font;
    box-shadow: $box-shadow;
    margin-bottom: 15px;
	._right{
      @media only screen and (max-width : 667px) {
        padding-right: 77px;
      }
	  padding-top: 10px;
	  .old-price{
		margin-top: 0 !important;
		padding-left: 5px;
	  }
	  .new-price{
		margin-top: -10px;
	  }
	}
    .icon-side{
      width: 100%;
      margin: 10px auto;
      text-align: center;
      padding: 5px 0 0;
      border-top: none !important;
      .icon{
        display: inline-block;
        width: 40px;
        background-position: center top;
        margin: 0 6px 10px 6px;
        vertical-align: middle;
        &:nth-of-type(1){
          margin-left: 0;
        }
      }
      .status-icon{
        background-position: left center;
        color: #35CB35;
        display: block;
        font-size: 12px;
        height: 20px;
        margin: auto;
        padding: 1px 0 0 15px;
        text-align: left;
        vertical-align: middle;
        width: 205px;
      }
      border-top: 1px solid rgba(0,0,0,0.15);
    }
	#ratings{
		cursor: pointer;
		display: inline-block;
	}
    .anzahl{
      text-align: center;
      margin-bottom: 5px;
      p{
        display: inline-block;
        font-size: 90%;
        font-family: "Sarala", sans-serif;
        margin-bottom: 5px;
        position: relative;
        &:nth-of-type(2)::before{
          content: "|";
          color:#000;
          margin:0 6px;
        }
        /*strong{
          font-family: "Sarala-bold", sans-serif;
        }*/
      }
    }
    .verkauft{
      border:1px solid rgba(0,0,0,0.15);
      background-color: #eaeaea;
      padding-left: 0;
      text-align: center;
      margin: 15px 0;
      span{
        color: #b8282e;
        text-align: center;
        font-family: "Sarala-bold", sans-serif;
        margin-right:5px;
      }
    }
    .not-available{
      color:#f00;
      text-align:center;
      text-transform: uppercase;
    }
    .checkbox{
      margin: 20px auto 15px;
      text-align: left;
      @media only screen and (max-width : 667px) {
        display: table;
        margin: 0 auto 15px;
        .control{
          margin: 0;
          padding-top: 1px;
        }
      }
      .control__indicator {
        height: 26px;
        width: 26px;
        &::after{
          width: 6px;
          height: 14px;
        }
      }
      .control{
        font-size: 14px;
        padding-left: 35px;
        span{
          padding-left: 4px;
          font-family: "Sarala", sans-serif;
        }
      }
    }

    .payments{
      width: 100%;
      margin: 15px auto 0;
      //border-top: 1px solid rgba(0,0,0,0.15);
      padding: 5px 0 10px 0;
      text-align: center;
      svg{
        margin: 0 auto;
        display: inline-block;
        width: 62px;
        height: 35px;
        padding: 3px 3px;
        border-radius: 4px;
        border: 1px solid #ddd;
        background-color: whitesmoke;
      }
    }
    .rating{
      border-top: 1px solid rgba(0,0,0,0.15);
      width: 100%;
      margin: 0 auto;
      padding: 15px 0 10px 0;
      display: table;
      svg{
        border: 2px solid $color_2;
        width: 90px;
        height:90px;
        border-radius: 100px;
        box-shadow: $box-shadow;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .faq {
    width: 100%;
  }
}

.btn-download:hover::after, .btn-paypal:hover::after {
  width: 100%;
}
.btn-download{
  position: relative;
  z-index: 0;
  display: table;
  margin: 20px auto;
  text-decoration: none;
  color: white !important;
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  width: 100%;
  height: 50px;
  padding: 0 10px 0 15px;
  border-width: 1px;
  border-style: solid;
  border-color:#f89406 #f89406 #ad6704;
  background-color: #faa732;
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  text-transform: uppercase;
  font-family: "Sarala-bold", sans-serif;
  font-size: 18px;
  text-align: center;
  &:hover{
    border-color:#ad6704;
  }
  span, svg{
    vertical-align: middle;
  }
  svg{
    margin-right: 15px;
    width: 25px;
    height: 45px;
    fill: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  }
  &::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $colorbuyhover;
    transform-origin: left center 0;
    z-index: -1;
    transition: width 0.35s ease;
  }
}
.discount{
  float: left;
  display: inline-block;
  vertical-align: top;
  margin: 15px 20px 0 0;
  width: 60px;
  height: 60px;
  background-color:#b8282e;
  color: #fff;
  font-size: 30px;
  font-family: "Sarala-bold", sans-serif;
  text-align: center;
  line-height: 2;
  @media only screen and (max-width : 1024px) {
    margin: 15px 0 0 16px;
  }
}
._right{
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0 auto;
  //width: 160px;
}
.offer{
  .new-price{
    text-align: left;
    span {
      &:nth-of-type(2){
        color: #b8282e !important;
      }
    }
  }
}
.new-price{
  text-align: center;
  display: block;
  margin: 0 auto;
  span{
    &:nth-of-type(1) {
      font-size: 2.75em;
      display: inline-block;
      vertical-align: top;
      font-family: 'Titillium-regular', sans-serif;
      color: $font;
    }
    &:nth-of-type(2){
      font-family: 'Titillium-bold', sans-serif;
      display: inline-block;
      font-size: 2.75em;
      margin: 0 2px 0;
      color: $color;
    }
  }
}
.old-price{
  margin-top: -5px;
  font-family: 'Titillium-bold', sans-serif;
  font-size: 1.35em;
  color: #585858;
  text-decoration: line-through;
  span{
    font-size: 100%;
  }
}
.old-price-statt{
  margin-top: -5px;
  font-family: 'Titillium-bold', sans-serif;
  font-size: 1.35em;
  color: #585858;
  span{
    font-size: 100%;
  }
  p{
    &:nth-of-type(1) {
      text-decoration: line-through;
    }
    &:nth-of-type(2) {
      font-size: 14px;
    }
  }
}


/*.btn-paypal{
  position: relative;
  z-index: 0;
  display: table;
  margin: 20px auto;
  text-decoration: none;
  color: #000;
  width: 100%;
  height: 50px;
  overflow: hidden;
  padding: 15px 10px 0 15px;
  background: #FEE1A5;
  background: -moz-linear-gradient(top, #FFF, #FEE1A5 50%, #FFB829 51%, #FEE1A5 100%, #FEE1A5);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10, #FFF), color-stop(.1, #FEE1A5), color-stop(.51, #FFB829), color-stop(.95, #FEE1A5), color-stop(100, #FEE1A5), to(#FEE1A5));
  text-transform: uppercase;
  font-family: "Sarala-bold";
  font-size: 11px;
  text-align: center;
  border: 1px solid #FFB829;
  span{
    background:url(icons/payments/PayPal.png) no-repeat center right;
    background-size: 80px;
    width: 80px;
    height: 22px;
    margin:0 0 0 10px;
    display: inline-block;
    vertical-align:middle;;
  }
  &::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color:#FFB829;
    transform-origin: left center 0;
    z-index: -1;
    transition: width 0.35s ease;
  }
}*/

/* special prize listing*/
._right.offer.special-prize{
	  .old-price{
		margin-top: 0 !important;
		padding-left: 5px;
	  }
	  .new-price{
		margin-top: -10px;
	  }
}
