#index{
/*  .subheadline{
    display: none;
  }*/
  .menu-header {
    margin-bottom: 0;
      .top-left {
        display: table;
        vertical-align: top;
        width: 990px;
        margin: 0 0 0 auto;
        @media only screen and (max-width: 1400px) {
          width: 680px;
        }
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
  }
  .left-category-sidebar{
    position: relative;
    float: left;
    z-index: 5;
    margin-top: -25px;
    margin-right: 15px;
    width: 290px;
    display: inline-block;
    .sidebar-category{
      height: 710px;
      padding: 0 0 1px 10px;
      h6{
        padding: 0 15px 0 0;
        margin: -2px 0 0 -10px;
        font-size: 15px;
        height: 25px;
        &::after{
        border-top: 25px solid $font;
      }
      }
    }
    ul{
      li{
        height: 25px; //firefox support
        padding: 4px 0;
        font-size: 14px;
        font-family: "Titillium-bold", sans-serif;
      }
    }
  }
  .index-right-sidebar{
    margin-top: 0;
    width: 290px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
  }



  .index-sidebar{
    margin-top: 0;
    width: 290px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
  }
  .sly-holder{
    margin-top: 10px;
    margin:0 0 40px;
  }
  .intro{
    background:rgba(0,0,0,0.7) url('images/bgs/vorlagen-bg.svg') no-repeat center top 0 fixed;
    background-size:2120px 610px;
    height: 400px;
    position: relative;
    width: 100%;
    &::after{
      content: "";
      background: rgba(255,255,255,0.15);
      width: 100%;
      height: 100%;
      margin: 0;
      left: 0;
      top: 0;
      position: absolute;
      @media only screen and (max-width : 667px) {
        background-color: transparent;
      }
    }
    @media only screen and (max-width : 1024px) {
      height: 350px;
    }
    .startseite-holder{
      display: table;
      text-align: center;
    }
    h1 {
      padding: 4% 0 0;
      width: 100%;
      margin: 0 auto 0 0;
      display: block;
      text-align: left;
      position: relative;
      color: white;
      font-size: 2.5em;
      text-shadow:0 1px 1px rgba(0, 0, 0, 0.3);
      z-index: 1;
      ._one, ._seit{
        line-height: 1;
        padding: 5px 20px 8px 20px;
        margin:0 auto 10px auto;
        background-color: rgba(87, 148, 0, 1);
        display: table;
      }
      ._middle {
        display: table;
        margin:0 auto 10px auto;
        background-color: rgba(60, 66, 78, 1);
        padding: 0 20px 5px 20px;
        line-height: 1.5;
        height: 57px;
        overflow: hidden;
        text-align: center;
        color: $color_white;
        font-size: 110%;
        @media only screen and (max-width : 1400px) {
          height: 48px;
        }
        @media only screen and (max-width : 1024px) {
          height: auto;
        }
        @media only screen and (max-width : 667px) {
          //color: $font;
          text-shadow: none;
          //margin: 0 auto;
          text-align: center;
          font-size: 100%;
          line-height: 1.3;
        }
      }
      //TWO MAIN BREAKING POINTS
      @media only screen and (max-width : 1400px) {
        font-size: 1.85em;
      }
      @media only screen and (max-width : 1024px) {
        padding-top: 5%;
      }
    }
  }
  .sidebar-side{
    margin-top: 0;
    height: auto;
  }
  .diagonal{
    border-left: 1900px solid $background;
    border-top: 70px solid transparent;
    bottom: 0;
    position: absolute;
    z-index: 11;
  }
}
.startseite-holder {
  display: inline-block;
  vertical-align: top;
  width: 990px;
  margin: 0 0 0 auto;
  overflow: hidden;
  @media only screen and (max-width: 1400px){
    width: 680px;
  }
  @media only screen and (max-width : 1024px) {
    width:100%;
  }
}
.subintro-holder{
    display:table;
    width: 100%;
    margin: 57px auto 25px;
}
._sf{
  text-transform: uppercase;
  position: relative;
  font-size: 17px;
  font-family: "Sarala-bold", sans-serif;
  display: table-cell;
  width: 200px;
  vertical-align: middle;
}
.subintro{
  margin:0;
  display: table-cell;
  p{
    float: left;
    width: 33.33%;
    text-align: center;
    vertical-align: middle;
    position: relative;
    &::after{
      position: absolute;
      content: "";
      left: 90%;
      top: 10%;
      background:url('images/svg/right-arrow-step.svg') no-repeat center;
      width: 50px;
      height: 50px;
      @media only screen and (max-width: 1400px){
        left: 90%;
        top: 5px;
        width: 40px;
        height: 40px;
      }
      @media only screen and (max-width: 720px){
        left: 85%;
        width: 35px;
        height: 35px;

      }
    }
    &:last-of-type{
      &::after{
        display: none;
      }
    }
    svg{
      border: 4px solid $font;
      background-color: #fff;
      border-radius: 50px;
      padding: 7px;
      fill: $font;
      width: 50px;
      height: 50px;
    }
    span{
      margin-top: 5px;
      font-size: 14px;
      text-transform: uppercase;
      font-family: "Sarala-bold", sans-serif;
      display: block;
    }
  }
}
@media only screen and (max-width: 1400px){
  ._sf{
    font-size: 15px;
    width: 160px;
  }
}
@media only screen and (max-width: 720px){
  .subintro-holder {
    width: 100%;
    margin: 0;
    ._sf{
      display: block;
      width: 100%;
      margin-top: 10px;
    }
    .subintro {
      margin: 8px 0 0;
      display: block;
      p {
        margin-top: 0;
        span{
          font-size: 14px;
          margin-bottom: 10px;
          &::before{
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 13px solid $color;
          }
        }
        svg{
          border: 3px solid #3c424e;
          width: 42px;
          height: 42px;
          padding: 3px;
        }
      }
    }
  }
}
@media only screen and (max-width: 510px) {
  .subintro-holder {
    .subintro {
      p {
        span {
          font-size: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 370px) {
  .subintro-holder {
    .subintro {
      p {
        span {
          font-size: 9px;
        }
      }
    }
  }
}
.facts-box{
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  .fact{
    float: left;
    width: 20%;
    text-align: center;
    padding-top: 22px;
    svg{
      width: 25px;
      height: 25px;
      fill: $color;
      display:inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
    span{
      margin: 0 auto;
      text-transform: uppercase;
      line-height: 1.2;
      width: 160px;
      text-align: left;
      font-size: 16px;
      color: #fff;
      display:inline-block;
      vertical-align: middle;
      font-family: "Titillium-bold", sans-serif;
    }
    @media only screen and (max-width : 1400px) {
      padding-top: 10px;
      width: 19.5%;
      svg{
        width: 20px;
        height: 20px;
        fill: $color;
        margin-right: 0;
        margin-bottom: 4px;
      }
      span{
        display: block;
        text-align: center;
        width: 140px;
        font-size: 14px;
      }
    }
  }
}

.sparten-box{
  box-shadow: $box-shadow;
  background: #fff;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  padding: 9px 20px 0 50px;
  height: 123px;
  p{
    float: left;
    width: 25%;
    svg{
      width: 15px;
      height: 15px;
      fill: $color;
      display: inline-block;
      vertical-align: middle;
    }
    a{
      word-break: break-all;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      margin-bottom: 4px;
      color: $font;
      font-family: "Titillium-bold", sans-serif;
      font-size: 16px;
      &:hover{
        color: $color;
        text-decoration: underline;
      }
      &::before{
        content: "";
        display: inline-block;
        background:$color url('images/right-arrow.png') center no-repeat;
        background-size: 11px 12px;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 5px;
        border-radius: 10px;
      }
    }
  }
  @media only screen and (max-width : 1400px) {
    padding: 14px 0 10px 10px;
    margin: 10px auto 20px;
    p{
      a{
        font-size: 0.85em;
      }
    }
  }
  @media only screen and (max-width : 1024px) {
    height: 100%;
    padding: 5px 10px 10px;
    p{
      a{
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width : 860px) {
    padding: 5px 10px 8px;
    p{
      padding:2px 0;
      width: 50%;
      a{
        font-size: 1em;
        margin-bottom: 2px;
      }
      &:nth-of-type(17), &:nth-of-type(18), &:nth-of-type(19){
        display: none;
      }
    }
  }
  @media only screen and (max-width : 450px) {
    p{
      width: 98%;
    }
  }
}


//--LISTING
ul.listing{
  li{
    font-size: 15px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    padding: 10px 20px 20px;
    margin-bottom: 20px;
    a{
      text-decoration: none;
      display: block;
    }
    h3{
      padding: 0 0 8px;
      width: 100%;
      border-bottom: 1px solid #ccc;
    }
    .pull-right{
      float: right;
      text-decoration: none;
      margin-top: 3px;
    }
    ._about{
      padding: 10px 0;
    }
    ._product{
      display: table;
      background-color: #fff;
      box-shadow: 0 0 1px 1px rgba(0,0,0,0.25);
      transition: $transition;
      margin: 10px auto;
      width: 90%;
      padding: 20px;
      border-left: 3px solid $color;
      @media only screen and (max-width: 1400px) {
        width: 100%;
      }
      ._image{
        display: table-cell;
        width: 120px;
        vertical-align: top;
        padding-right: 15px;
        img{
          width: 120px;
          height: auto;
          transition: $transition;
        }
      }
      .cont{
        display: table-cell;
        vertical-align: top;
        h4{
          padding: 0 0 8px;
          font-weight: bold;
        }
        ._excerpt{
          color: $font;
        }
        .btn{
          margin: 20px 0 0 auto;
        }
      }
      &:hover {
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      }
      &:hover .btn::after{
        width: 100%;
      }
    }
  }
}

// LET'S MAKE THIS THING RESPONSIVE
@media only screen and (max-width: 667px) {
  .sparten-box{
    margin-top: 0;
    margin-bottom: 0;
    width: 104%;
    margin-left: -2%;
  }
  ul.listing{
    width: 104%;
    margin-left: -2%;
  }
  #index {
    .intro {
      background-image: none;
      background-color: #fff;
      height: 200px;
      box-shadow: $box-shadow;
      h1{
        padding-top: 10px;
        ._middle{
          background-color: transparent;
            line-height: 1.2;
            color: $font;
            text-shadow: none;
        }
        ._one, ._seit{
          display: none;
        }
      }
    }
  }
  .facts-box{
    height: auto;
    text-align: center;
    padding-bottom: 10px;
    background:#fff;
    .fact{
      display: inline-block;
      float: none;
      width: 32.5%;
      span{
        color: $font;
      }
    }
  }
  ul.listing li{
    .pull-right {
    display: none;
    }
    h3{
      font-size: 1.2em;
    }
    ._product{
      padding: 15px 10px;
      ._image{
        width: 70px;
        margin:0 auto;
        display: block;
        img {
          width: 70px;
        }
      }
      .cont{
        display: block;
        width: 100%;
        h4{
          padding: 10px 0;
          font-size: 1.1em;
        }
        ._excerpt{
          font-size: 14px;
        }
        .btn{
          margin:10px auto 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 560px) {
  #index{
    .intro{
      height: 185px;
      h1{
        font-size: 1.3em;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  #index {
    .intro {
      height: 190px;
      h1 {
        span{
          line-height: 1.2;
        }
      }
    }
  }
  .facts-box{
    height: auto;
    padding-left: 10px;
    padding-bottom: 5px;
    .fact{
      padding-top: 2px;
      text-align: left;
      width: 100%;
      svg{
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      span{
        display: inline-block;
        width: auto;
      }
    }
  }
}