body#Bestellung, body#Warenkorb{
  h1{
    color: $font;
  }
  .indicator{
    margin-bottom: 20px;
    @media only screen and (max-width: 980px){
      padding: 15px 0 30px;
    }
    @media only screen and (max-width: 667px){
      margin-bottom: 0;
    }
  }
  .sly-holder{
    margin-top: 20px;
    @media only screen and (max-width: 980px){
      margin-top: 0;
    }
  }
  .menu-header{
     margin-bottom: 0;
  }
}

#register-link {
  padding-bottom: 10px;
}
body.paymentgates{
  .pre-header {
    box-shadow: none;
    .logo{
      @media only screen and (max-width: 980px) {
        margin-top: -50px;
      }
    }
    .trusted-shops {
     float: right;
      margin-right: 0;
    }
    @media only screen and (max-width: 980px) {
      padding: 0;
      .trusted-shops {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 667px){
    footer{
      margin-top: 0;
    }
  }
}



.hide{
  display: none !important;
}
.paymethod_dropdown{
  //width: 760px;
  font-size: 14px;
  border-left: 2px solid $font;
  margin: 20px 0 10px 40px;
  padding: 10px 20px 10px 20px;
  background-color: $background;
  border-top: 1px solid rgba(0,0,0,0.05);
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-right: 1px solid rgba(0,0,0,0.05);
  input, select{
    border:1px solid rgba(0,0,0,0.15);
  }
  .info{
    margin: 5px 0 10px;
    font-family: "Sarala-bold", sans-serif;
    color: #000;
    font-size: 13px
  }
}
#input_email {
  .controls {
    width: 690px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 10px;
    input{
      width: 100%;
      padding: 10px 0 10px 10px;
    }
  }
}
.controls{
  position: relative;
  input[type="text"]{
    width: 100%;
    padding: 8px 0 8px 10px;
  }
  .fa-times {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 15px;
    &::before, &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background: red;
    }
    &::before {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &::after {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .fa-check{
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 15px;
    height: 15px;
    &::after{
      content: "";
      position: absolute;
      width: 8px;
      height: 16px;
      transform: rotate(45deg);
      border-style: solid;
      border-color: green;
      border-image: initial;
      border-width: 0px 2px 2px 0px;
    }
  }
}

span.info{

}
.help-block.error{
  display: block;
  color:#ff0036;
  font-size: 13px;
}
.paymethod{
  border-bottom: 1px solid $font;
  padding: 20px 0;
  width: 100%;
  &:nth-of-type(1){
    border-top:1px solid $font;
  }
    svg{
      width: 100px;
      height: 40px;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #ccc;
      padding:3px 5px;
      border-radius: 4px;
      background-color: whitesmoke;
  }
  .double_card{
    width: 100px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ccc;
    padding:0 3px;
    border-radius: 4px;
    background-color: whitesmoke;
    svg{
      border:0;
      width: 45px;
      height: 37px;
      padding: 0;
    }
  }
  h3{
    width: 195px;
    font-size: 20px;
    padding:0 15px;
    vertical-align: middle;
    display: inline-block;
    @media only screen and (max-width: 980px){
      width: auto;
    }
  }
  .paymethod_info{
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    width: 60%;
    @media only screen and (max-width: 1400px){
      width: 52%;
    }
    @media only screen and (max-width: 980px){
      width: 45%;
    }
    @media only screen and (max-width: 800px){
      margin-top: 5px;
      width: 100%;
    }
  }
}




.down-butns-za{
  margin: 30px 0;
  .btn-zuruck, .bezahlen-box{
    display: inline-block;
    vertical-align: top;
  }
  .btn-zuruck{
    margin-top: 26px;

  }
  .bezahlen-box{
    float: right;
    text-align: center;
    width: 500px;
    padding: 0;
    font-size: 15px;
    span{
      height: 22px;
      text-transform: uppercase;
      font-size: 13px;
      font-family: "Sarala-bold", sans-serif;
    }
    .btn{
      text-transform: uppercase;
      font-size: 16px;
      width: 100%;
      height: 50px;
      font-family: "Sarala-bold", sans-serif;
      margin-top: 5px;
      border: 1px solid $colorbuyhover;
      text-shadow: 0 1px 1px rgba(0,0,0,0.5);
      background-color: #faa732;
      background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
      padding-bottom: 2px;
      &::after{
        background-color: $colorbuyhover;
      }
    }
  }
}


//--MAKE THIS PAGE RESPONSIVE
@media only screen and (max-width: 1400px) {
  .down-butns-za{
    .bezahlen-box{
      width: 58%;
    }
  }
}
@media only screen and (max-width: 667px) {
  .down-butns-za{
    margin: 20px 0;
    .btn-zuruck{
      display: none;
      //display: block;
      margin: 10px auto 0;
      width: 250px;
    }
    .bezahlen-box{
      float: none;
      width: 100%;
    }
  }
  .table_payment {
    div {
      &:nth-child(1){
        width: 65px;
      }
      &:nth-child(2) {
        display: block;
        width: auto;
        padding: 10px 5px;
        border-bottom: 0;
      }
      &:nth-child(3) {
        display: block;
        width: auto;
        padding: 0 5px 10px;
      }
      h3{
        font-size: 16px;
        padding: 0 0 0 5px;
      }
    }
    .paymethod_info{
      font-size: 13px;
    }
    ._border{
      &:last-of-type{
        border-top: none;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .table_payment {
    div {
      h3{
        font-size: 14px;
      }
    }
    .paymethod_info{
      font-size: 12px;
      height: 50px;
    }
    .img-payment{
      width: 80px;
      background-size: 70px auto;
    }
  }
}

//--CHECKBOX STYLE LEVEL2

.control_in{
  margin: 15px 0;
  display: block;
}
.control {
  display: inline-block;
  margin: 5px 0;
  padding-left: 35px;
  position: relative;
  line-height: 1.9;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Sarala-bold', sans-serif;
  text-transform: uppercase;
}
.control--radio{
  .control__indicator:after {
    top: 4px;
    width: 6px;
    height: 13px;
  }
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background: #fff;
  border: 2px solid $font;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
 // background:  #2aa1c0;
}

.control input:checked ~ .control__indicator {
  //background: #f89406;
  background-color: $color;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  //background: #f89406;
  background-color: $color;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
  left:0;
  right: 0;
  top: 0;
  margin: auto;
  width: 7px;
  height: 16px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

//--CHECKBOX STYLING
.control-payment{
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin:0 15px 0 0;
  .control__indicator {
    height: 40px;
    width: 40px;
   &:after {
     top: 4px;
     width: 8px;
     height: 18px;
     border-width: 0 3px 3px 0;
   }
  }
  @media only screen and (max-width: 667px){
    margin-right: 5px;
  }
}

//--CHECKBOX STYLING -- NOT IN USE

/*.checkboxThree {
  width: 100px;
  height: 30px;
  background: #333;
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  position: relative;
  input[type=checkbox] {
    visibility: hidden;
  }
}
//-- Create the text for the On position
.checkboxThree:before {
  content: 'On';
  position: absolute;
  top: 7px;
  left: 13px;
  height: 2px;
  color: #26ca28;
  font-size: 13px;
  text-transform: uppercase;
}
//-- Create the label for the off position
.checkboxThree:after {
  content: 'Off';
  position: absolute;
  top: 7px;
  left: 65px;
  text-transform: uppercase;
  height: 2px;
  color: #ddd;
  font-size: 13px;
}
//-- Create the pill to click
.checkboxThree label {
  display: block;
  width: 52px;
  height: 22px;
  border-radius: 50px;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: 4px;
  z-index: 1;
  left: 8px;
  background: #ddd;
}
// -- Create the checkbox event for the label
.checkboxThree input[type=checkbox]:checked + label {
  left: 42px;
  background: #26ca28;
}
*/

//--PAYMETHOD DROPDOWN
#paymethod_invoice{
  width: 690px;
  margin-top: 10px;
  h3{
    padding: 15px 0;
    font-size: 18px;
    text-transform: uppercase;
  }
  .control-group{
    padding: 5px 0;
    label{
      color: #000;
      text-transform: uppercase;
      margin-bottom: 2px;
      display: inline-block;
    }
    .radio.inline{
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .control {
    width: 100%;
    padding-left: 0;
    text-transform: unset;
    #edit-btn{
      float: left;
      width: 45%;
    }

    #add-btn{
      width: 54%;
    }
    .btn{
      text-transform: unset;
      height: 17px;
      margin: 5px 0 0px auto;
    }
  }
  .controls{
    .fa-check{
      top: 10px;
    }
    .fa-times {
      top: 19px;
    }
  }
  .row-fluid {
    .span6 {
    width: 50%;
    float: left;
      &:nth-of-type(1){
        margin-right: 2%;
        width: 48%;
      }
    }
    .span4{
       width: 32%;
       float: left;
     }
    .span8{
      width: 66%;
      float: left;
      margin: 0 !important;
    }
  }
  select#country{
    margin: 10px 0;
    padding: 12px 0;
    background-color: white;
    width: 100%;
  }

  select#country_2{
    margin: 10px 0;
    padding: 12px 0;
    background-color: white;
    width: 100%;
  }
  select#sector{
    padding: 12px 0;
    background-color: white;
    width: 100%;
    font-family: "Sarala",​sans-serif;
  }
  .btn{
    text-transform: uppercase;
    height: 46px;
    width: 50%;
    margin: 5px 0 20px auto;
    font-size: 16px;
  }
  ._required{
    color: red;
    padding-left: 3px;
  }
  #invoice_form{
    background: #fff;
    padding: 0 20px;
    margin: 12px 0 15px;
    border: 1px solid rgba(0,0,0,0.15);
  }
}
@media only screen and (max-width: 1024px) {
  #paymethod_invoice, #input_email .controls {
    width: 100%;
  }
}
@media only screen and (max-width: 980px){
  .paymethod{
    padding: 11px 0;
  }
  .paymethod_dropdown{
    width: 100%;
    margin: 10px 0 0 0;
  }

}
@media only screen and (max-width: 667px){
  .paymethod{
    h3{
      padding: 0 0 0 10px;
      font-size: 18px;
    }
  }
  #paymethod_invoice {
    .row-fluid {
      .span6 {
        width: 100%;
        &:nth-of-type(1) {
          width: 100%;
          margin-right: 0;
        }
      }
      .span4 {
        width: 100%;
      }
      .span8 {
        width: 100%;
      }
    }
    .btn{
      width: 100%;
    }
    .control-group{
      padding: 3px 0;
      .radio.inline{
        margin-bottom: 5px;
      }
    }
    h3{
      padding: 10px 0;
    }
  }
  .control{
    font-size: 12px;
    padding-top: 3px;
    padding-left: 40px;
  }
}
@media only screen and (max-width: 410px){
  .paymethod{
    h3{
      padding:0;
    }
    svg, .double_card{
      display: none;
    }
  }
}
