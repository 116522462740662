//--USING ON OTHER TEMPLATES
.icon, .icon-set, .status-icon{
  margin: 0 auto 3px 0;
  background-size: 40px !important;
  background-position:center top;
  width: 100%;
  height: 61px;
  display: block;
  &:last-of-type{
    margin-bottom: 0;
  }
}

.cdcase{
  background: url('images/doc-icons/disc.png') no-repeat center top;
}
.pagewhite{
  background: url('images/doc-icons/pagewhite.png') no-repeat center top;
}
.dnl, .exe{
  background: url('images/doc-icons/vorlage_software.svg') no-repeat center;
}
.ppt{
  background: url('images/doc-icons/vorlage_powerpoint.svg') no-repeat center;
}
.status-green{
  background: url('images/doc-icons/status-green.png') no-repeat center;
  background-size: 15px !important;
}
.txt, .pagetext, .text{
  background: url('images/doc-icons/vorlage_standard.svg') no-repeat center top;
}
.zip{
  background: url('images/doc-icons/vorlage_zip.svg') no-repeat center top;
}
.pdf{
  background: url('images/doc-icons/vorlage_pdf.svg') no-repeat center top;
}
.doc, .docx{
  background: url('images/doc-icons/vorlage_word.svg') no-repeat center top;
}
.xls, .xlsx{
  background: url('images/doc-icons/vorlage_excel.svg') no-repeat center top;
}
.rtf{
  background: url('images/doc-icons/vorlage_rtf.svg') no-repeat center top;
}
.package{
  background: url('images/doc-icons/vorlage_zip.svg') no-repeat center top;
  height: 43px;
}
.mmp{
  background: url('images/doc-icons/vorlage_mindmap.svg') no-repeat center top;
}

.actual-price, .previous-price{
  display: block;
  span{
    font-size: 2.35em;
    &:nth-of-type(1) {
      color:$font;
      font-family: 'Titillium-regular', sans-serif;
    }
    &:nth-of-type(2) {
      font-family: 'Sarala-bold', sans-serif;
      color:$color;
    }
  }
}
.actual-price{
  width: 100%;
  color: $color;
  position: absolute;
  bottom: 42px;
  right: 0;
  left: 0;
  text-align: center;
}
.discount_price{
  span{
    &:nth-of-type(2){
      color:#b8282e;
    }
  }
}
.previous-price{
  color: grey;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 77px;
  font-family: 'Titillium-bold', sans-serif;
  text-decoration:line-through;
  text-shadow: 1px -1px 0 white;
  width: 140px;
  span{
    font-size: 1.15em;
    &:nth-of-type(1) {
      font-family: 'Titillium-bold', sans-serif;
      color: grey;
    }
    &:nth-of-type(2) {
      color: grey;
    }
  }
}

//-- STARTSITE SLIDER
//--SLY SLIDER CONFIGURATION

.sly-holder{
  position: relative;
 // background: url('images/vorlagenbg.png') no-repeat center;
  //background-size: 950px;
  @media only screen and (max-width: 1400px){
   // background-size: 850px;
  }
  @media only screen and (max-width: 1100px){
    //background-size: 550px;
  }
  @media only screen and (max-width: 667px){
    //background-size: 300px;
  }
  p{
    padding: 15px 0;
    text-align: center;
    span{
      color: $color;
      font-family: "Sarala-bold", sans-serif;
      text-transform: uppercase;
      font-size: 1.3em;
      vertical-align: middle;
      display: inline-block;
      @media only screen and (max-width: 420px){
        font-size: 1em;
      }
    }
    svg{
      margin: 0 5px;
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }
    a{
      font-size: 15px;
      text-decoration: none;
      vertical-align: middle;
    }
  }
}


.frame-slider {
  overflow: hidden;
  width: 95%;
  height: 356px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  @media only screen and (max-width : 1024px) {
    width: 100%;
  }
  ul{
    display: none;
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    a{
      text-decoration: none;
      color: $font;
      display: block;
      height: 100%; //to make whole div clickable
    }
    li.active{
      //border: 1px solid $color;
    }
    .btn{
      display: block;
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      bottom: -9px;
      width: 160px;
    }
    li{
      background-color: white;
      border:1px solid rgba(0,0,0,0.15);
      width: 299px;
      height: 340px;
      margin:0 5px 30px;
      position: relative;
      float: left;
      @media only screen and (max-width : 1400px) {
        width: 305px;
      }
      @media only screen and (max-width: 1024px){
       width: 290px;
      }
      &:hover{
        border: 1px solid $color;
      }
      &:hover .btn::after{
        width: 100%;
      }
     }
    h2{
      font-size: 1.25em;
      height: 78px;
      line-height: 1.2;
	  overflow: hidden;
      padding: 10px 3% 15px 3%;
    }
    .media-side{
      padding-left: 3%;
      display: inline-block;
      width: 20%;
      margin-right: 3%;
      vertical-align: top;
      img{
        width: 100%;
      }
    }
    .excerpt-side{
      display: inline-block;
      width: 175px;
      vertical-align: top;
      text-align: center;
      position: relative;
      font-size: 14px;
      color: $font;
      max-height: 152px;
      overflow: hidden;
      img{
        max-height: 150px; 
        max-width: 173px; 
        -webkit-box-shadow: 6px 6px 8px -7px rgba(0,0,0,0.35);
        -moz-box-shadow: 6px 6px 8px -7px rgba(0,0,0,0.35);
        box-shadow: 6px 6px 8px -7px rgba(0,0,0,0.35);
      }
    }
    .icon-side{
      display: inline-block;
      width: 60px;
      vertical-align: top;
      @media only screen and (min-width: 1400px){
        width: 60px;
      }
    }
    .excerpt-icon-side{
      display: inline-block;
      width: 77%;
      vertical-align: top;
      position: relative;
      font-size: 14px;
      color: $font;
      @media only screen and (min-width: 1400px){
        width: 70%;
      }
    }

  }
}
.scrollbar {
  width: 94%;
  margin: 0 auto;
  height: 1px;
  background: #ccc;
  line-height: 0;
  position: relative;
  .handle-slider {
    height: 3px;
    background:$font;
    cursor: pointer;
    border-radius: 20px;
    top: -1px;
    position: absolute;
    .mousearea {
      position: absolute;
      top: -9px;
      left: 0;
      width: 100%;
      height: 20px;
      z-index: 111;
    }
  }
}
.prev_s, .next_s {
  cursor: pointer;
  border: none;
  position: absolute;
  background: transparent;
  top: 50%;
  margin-top: -10px;
  @media only screen and (max-width : 1024px) {
    display: none;
  }
  svg{
    width: 30px;
    height: 30px;
    transition: $transition;
    &:hover{
      fill:$color;
    }
  }
}
.prev_s {
  left: -10px;
}
.next_s {
  right:-10px;
}




//--CAROUSELFREDSEL
.cs-slider{
  border-top: 2px solid $font;
  float: left;
  padding: 0;
  width: 100%;
  margin: 40px auto 0;
  position: relative;
  ul{
    width: 100%;
    overflow: hidden;
    margin: 0;
    position: relative;
    text-align: center;
    a{
      text-decoration: none;
      display: table;
    }
    li{
      background-color: white;
      border:1px solid rgba(0,0,0,0.15);
      width:23.7%;
      margin:0 0.5%;
      position: relative;
      display: inline-block;
      vertical-align: top;
      min-height: 330px;
      transition: $transition;
      &:hover{
        box-shadow: 0 1px 5px 1px rgba(0,0,0,0.25);
      }
      &:hover .btn::after{
        width: 100%;
      }
      @media only screen and (max-width : 980px) {
        padding-bottom: 0;
      }
    }
    h2{
      text-align: left;
      font-size: 1.15em;
      line-height: 1.3;
      padding: 10px 3% 15px 3%;
    }

    .media-side{
      padding-left: 3%;
      display: inline-block;
      width: 25%;
      margin-right: 3%;
      vertical-align: top;
      img{
        width: 100%;
      }
    }
    .excerpt-side{
      text-align: left;
      display: inline-block;
      width: 70%;
      vertical-align: top;
      position: relative;
      font-size: 14px;
      color: $font;
    }
    .icon-side{
      display: inline-block;
      width: 23%;
      vertical-align: top;
      @media only screen and (min-width: 1400px){
        width: 25%;
      }
    }
    .excerpt-icon-side{
      text-align: left;
      display: inline-block;
      width: 77%;
      vertical-align: top;
      position: relative;
      font-size: 14px;
      color: $font;
      @media only screen and (min-width: 1400px){
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width : 1400px) {
  .cs-slider{
    ul{
      li{
        height: 340px;
        ._value{
          font-size: 1.35em;
        }
      }
    }
  }
}

@media only screen and (max-width : 1024px) {
  .cs-slider {
    display: none;
  }
}

//--PRODUKTVORSCHAU SLICK SLIDER
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.slick-track {
  top: 0;
  left: 0
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: ''
}

.slick-track:after {
  clear: both
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}
.slick-slide img {
  display: block
}
.slick-slide.slick-loading img {
  display: none
}
.slick-slide.dragging img {
  pointer-events: none
}
.slick-initialized .slick-slide {
  display: block
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
};
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0
}
.slick-loading .slick-list {
  background: url(images/ajax-loader.gif) center center no-repeat #fff;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  height: 40px;
  width: 40px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: .25
}
.slick-next:before,
.slick-prev:before {
  line-height: 1;
}

.slick-prev {
  left: -35px;
  background: url('images/svg/chevron-left.svg') no-repeat center;
  background-size: 40px;
  display: block;
  @media only screen and (max-width: 580px){
    left: -45px;
  }
}

.slick-next {
  right: -35px;
  background: url('images/svg/chevron-right.svg') no-repeat center;
  background-size: 40px;
  display: block;
  @media only screen and (max-width: 580px){
    right: -45px;
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 30px
}
.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  color: $color;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #000
}
.slick-dots li.slick-active button:before {
  color: #000
}
.galerias{
  width: 165px;
  margin: 0 auto;
  text-align: center;
  .gallery{
    border: 1px solid #ccc;
    padding: 5px;
    img{
      width: 100%;
    }
  }
  .navgal{
    img{
      border: 1px solid #ccc;
      margin: 6px 3px;
      height: auto;
    }
     img.slick-current {
        border: 1px solid $color;
    }
  }
}

//--ÄHNLICHE, KÜRZLICH ANGESEHENE, LEXIKON RELATED

.aehnliche-vorlagen, .angesehen-vorlagen{
  .preheadline{
    padding: 0 0 20px 0;
    display: table;
    margin: 0 auto;
    p {
      text-transform: uppercase;
      display: table;
      background-color: $font;
      color: #fff;
      padding: 0 15px 0 20px;
      font-family: "Sarala-bold",sans-serif;
      line-height: 2;
      font-size: 15px;
      position: relative;
      height: 30px;
      text-align: center;
      &::before{
        content: "";
        position: absolute;
        vertical-align: middle;
        width: 0;
        height: 0;
        top: -2px;
        left: -20px;
        border-top: 32px solid $font;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
      }
      &::after{
        content: "";
        position: absolute;
        vertical-align: middle;
        width: 0;
        height: 0;
        top:-2px;
        right: -20px;
        border-top: 32px solid $font;
        border-bottom: 20px solid transparent;
        border-right: 20px solid transparent;
      }
    }
    @media only screen and (max-width : 720px) {
      padding-bottom: 5px;
      p {
        background-color: transparent;
        color: $font;
        padding: 0 10px;
        font-size: 14px;
        &::before, &::after {
          display: none;
        }
      }
    }
  }
}
.aehnliche-vorlagen{
  width: 100%;
  float: left;
  margin: 30px 0 0;
  padding: 0 0 30px 0;
  border-top:2px solid $font;
  min-height: 370px;
  position: relative;
  @media only screen and (max-width : 720px) {
    margin-top: 0;
  }
  .frame-slider{
    width: 95%;
    @media only screen and (max-width : 1024px) {
      width: 100%;
    }
    li{
      width: 298px;
      @media only screen and (max-width : 1400px) {
        width: 305px;
      }
    }
  }
  .prev_s, .next_s{
    top: 50%;
    margin-top: -30px;
    @media only screen and (max-width : 1024px) {
      display: none;
    }
  }
  .prev_s{
    left: -1.25%;
  }
  .next_s{
    right: -1.25%;
  }
}

.grad {
  background: white;
  background: -webkit-linear-gradient(left top, #EEEEEE, white); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #EEEEEE, white); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #EEEEEE, white); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #EEEEEE, white); /* Standard syntax */
}