.order-box{
  width: 1000px;
  display: inline-block;
  margin: 0 auto 0 0;
  vertical-align: top;
  @media only screen and (max-width: 1400px){
    width: 700px;
  }
  @media only screen and (max-width : 1024px) {
    width:100%;
  }
  h1{
    padding: 0 0 10px;
  }
}

#lingoking-section {
  width: 280px;
  height: auto;
  /* display: inline-block; */
  /* vertical-align: top; */
  /* float: right; */
  position: absolute;
  top: 0px;
  right: 0px;
  @media only screen and (max-width : 1024px) {
    display: none;
  }
}
.f-probleme{
  ._ssf{
    margin-top: 15px;
    position: relative;
    z-index: 1;
    font-size: 15px;
  }
  ._vs_bg {
    top:auto;
    bottom: 20px;
    background-size: 120px;
    height: 145px;
    width: 120px;
  }
  ._mail{
    margin-bottom: 10px;
  }
}
.download-data{
  box-shadow: $box-shadow;
  display: table;
  width: 100%;
  .download-row{
    padding:0 20px !important;
  }
  section.download {
    border-bottom: 1px solid #ddd;
    padding:10px 0 15px;
    &:last-of-type{
      border-bottom: 0;
    }
    .media {
      display: table-cell;
      vertical-align: top;
      width: 55px;
      padding-right: 15px;
      i{
        margin-top: 6px;
        margin-bottom: 0;
      }
    }
    form {
      .f_name{
        font-size: 14px;
        display: inline-block;
        margin-bottom: 5px;
        padding-right: 15px;
      }
      /*display: table-cell;*/
      vertical-align: top;
    }
	> span {
	  display: table-cell;
	  vertical-align: middle;
	}
  }
  .tr-dd{
    display: table-row;
  }
  .tr-dd-first{
    border-top: 1px solid $color;
    color: #fff;
    background-color: $color;
    @media only screen and (max-width: 667px){
      background-color: #fff;
      color: $font;
    }
    &:first-of-type{
      border-top: 1px solid $font;
    }
  }
  .r-dd, .l-dd{
    display: table-cell;
  }
  h3{
    padding: 0;
    font-size: 1.15em;
    font-family: "Sarala", sans-serif;
  }
  .l-dd {
    vertical-align: top;
    font-family: "Titillium-bold", sans-serif;
    border-bottom: 1px solid white;
    background-color: $font;
    width: 155px;
    padding: 10px 0 10px 15px;
    color: white;
    text-transform: uppercase;
  }
  .l-dd-last {
    border-bottom: 1px solid $font;
  }
  .r-dd{
    padding: 10px 10px 10px 20px;
    vertical-align: middle;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
.btn-rechnung{
  text-transform:lowercase !important;
}
.btn-download-produkt{
  padding: 0 5px 0 25px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 auto 0 0;
  text-transform: uppercase;
  overflow: hidden;
  font-size: 14px;
  &::after{
    border-radius: 20px;
  }
  svg{
    float: right;
    width: 25px;
    height: 35px;
    margin-left: 12px;
    vertical-align: middle;
    display: inline-block;
  }
  @media only screen and (max-width : 760px) {
    padding:0 20px;
    svg{
     display: none;
    }
  }
}

.additional{
  display: table;
  margin: 50px 0;
  width: 100%;
  vertical-align: top;
  @media only screen and (max-width : 667px) {
    margin: 20px 0 30px;
  }
  .vorlagen-light{
    display: table-cell;
    width: 115px;
    vertical-align: top;
	float: left;
    svg{
      width: 90px;
      height: auto;
      fill:$color_2;
      opacity: 0.75;
    }
    @media only screen and (max-width : 667px) {
      display: none;
    }
  }
  ul{
    vertical-align: top;
    display: table-cell;
    .add-wrap{
      border: 1px solid #ccc;
      box-shadow: $box-shadow;
      padding: 10px 20px;
	  min-height: 115px;
    }
    li{
      width: 100%;
      padding: 5px 0;
    }
    p{
      display: table;
      font-family: "Sarala-bold", sans-serif;
      padding:15px 0 0;
      border-bottom: 1px solid $color;
    }
    span{
      display: block;
      padding:0;
	  width: 100%;
	  overflow: hidden;
    }
    strong.green_underline{
      border-bottom: 1px solid $color;
    }
  }

}


.iframe-vorlagen{
  margin: 0 0 60px 0;
  text-align: center;
  padding: 0;
  width: 100%;
  min-height: 500px;
  background-color: $font;
  color: white;
  iframe{
    //min-height: 500px;
  }
}

@media only screen and (max-width : 667px) {
  .download-data {
    display: table;
    border: 1px solid #ccc;
    .tr-dd {
      display:block;
    }
    .tr-dd-first {
      border-top: 0;
    }
    .l-dd, .r-dd {
      border: none;
      display: block;
    }
    .l-dd{
      width: 100%;
      padding: 10px 20px;
    }
  }
  ul.additional-to-download {
    span{
      padding: 5px 20px;
    }
  }

}