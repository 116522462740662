//--LEFT CATEGORY SIDEBAR BOX
.left-category-sidebar{
  width: 300px;
  display: inline-block;
  vertical-align: top;
  margin-right: 19px;
  @media only screen and (max-width: 1024px) {
    display: none !important;
  }
}
.sidebar-category{
  > ul{
    padding-left: 0 !important;
  }
  ul {
    width: 100%;
    padding-left:15px;
    margin-bottom: -2px;
      li.active > a{
        color: $color;
      }
      li {
        padding: 2px 0;
      }
      span{
        display: table-cell;
        vertical-align: top;
        &::before{
          content: "";
          display: inline-block;
          margin-bottom: 2px;
          margin-right: 6px;
          width: 5px;
          height: 5px;
          vertical-align: middle;
          background-color: $color;
        }
      }
      a {
        display: table-cell;
        color: $font;
        &:hover {
          color: $color;
          text-decoration: underline;
        }
      }
   }
}

//--MAIN SIDEBAR BOX
.sidebar-box{
  box-shadow:$box-shadow;
  margin: 0 auto 15px auto;
  width: 100%;
  padding: 0 15px 10px 15px;
  border-top: 2px solid $font;
  background:#fff;
  position: relative;
  word-wrap: break-word;
  z-index: 1;
  h6{
    text-shadow: 1px 1px 1px rgba(0,0,0,1);
    background-color: $font;
    display: table;
    text-align: left;
    padding: 0 12px 0 15px;
    margin-left: -15px;
    margin-bottom: 5px;
    color: white;
    height: 22px;
    line-height: 1.7;
    font-size: 13px;
    font-family: 'Sarala-bold', sans-serif;
    text-transform: uppercase;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      vertical-align: middle;
      width: 0;
      height: 0;
      top: 0;
      right: -15px;
      border-top: 22px solid $font;
      border-bottom: 20px solid transparent;
      border-right: 15px solid transparent;
    }
  }
  a{
    text-decoration: none;
  }
}

.right-page-sidebar {
  width: 280px;
  height: auto;
  display: inline-block;
  vertical-align: top;
  float: right;
  position: relative;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.additional-right{
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  background: #fff;
  width: 100%;
  min-height: 50px;
  padding: 0;
  margin-bottom: 15px;
  img{
    margin: 0 auto;
    display: block;
  }
  ul{
    a{
      text-decoration: none;
      display: block;
      color: $font;
    }
    li{
      padding:10px 10px 15px;
      transition: $transition;
      position: relative;
      &::after{
        content: "";
        width: 90%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        top: 100%;
      }
      &:last-of-type::after{
        display: none;
      }
      h2{
        line-height: 1.2;
        padding: 0 0 15px;
        font-size: 1.35em;
      }
      .thumb{
        width: 80px;
        padding-right: 10px;
        display: table-cell;
        vertical-align: top;
        img{
          width: 100%;
        }
      }
      .btn{
        margin-bottom: 5px;
        min-width: 140px;
      }
      .desc{
        vertical-align: top;
        display: table-cell;
        color: $font;
        font-size: 13px;
      }
      &:hover{
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0,0,0,0.15);
      }
      &:hover ::after{
        width: 100%;
      }
    }
  }
}
.faq {
  ._vs_bg{
    top: 10px;
    position: absolute;
    background: url(images/vorlagen-stuetzung.jpg) no-repeat top right;
    background-size: 130px;
    height: 162px;
    right: 2px;
    width: 130px;
  }
  ._wsd{
    position: relative;
    margin-top: 14px;
    text-transform: uppercase;
    font-family: "Sarala-bold", sans-serif;
  }
  ._phone{
    margin: 20px 0;
    position: relative;
    svg{
      fill: $color;
      width: 22px;
      height: 22px;
      margin-right: 5px;
      vertical-align: top;
    }
    a{
      text-decoration: none;
      font-family: "Sarala-bold", sans-serif;
      color: $font;
      &:hover{
        color: $color;
      }
    }
    span{
      display: block;
      font-size: 12px;
    }
  }
  ._mail{
    margin: 20px 0 10px 0;
    position: relative;
    svg{
      fill: $color;
      width: 22px;
      height: 22px;
      margin-right: 5px;
      vertical-align: top;
    }
    a{
      color: $font;
      font-family: "Sarala-bold", sans-serif;
      text-decoration: none;
      &:hover{
        color: $color;
      }
    }
  }
}

.gefallt-box{
  > span{
    display: block;
    margin:15px 0 5px;
    font-size: 15px;
  }
  a.fb{
    color: #fff;
  }
  .vorlagen-facebook{
    margin-top: 10px;
    border-top: 2px solid #3C424E;
    width: 100%;
    display: inline-block;
    height: 130px;
    position: relative;
    &:hover {
      &::after{
       background-color: transparent;
      }
      .like-box{
        background-color: rgba(59,89,152,1);
      }
   }
    &::after{
      transition: $transition;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 105%;
      background-color: rgba(255,255,255,0.25);
    }
    .logo{
      top: 8px;
      left: 10px;
      position: absolute;
      z-index: 11;
      width: 170px;
      height: 40px;
    }
    .like-box{
      z-index: 1;
      background-color: rgba(59,89,152,1);
      //background-color: #4080ff;
      position: absolute;
      top:70px;
      left: 0;
      right: 0;
      margin: auto;
      width: 185px;
      height: 35px;
      .l__part{
        float: left;
        padding: 3.5px 0 3.5px 7px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Titillium-bold", sans-serif;
      }
      .f__logo{
        float: right;
        padding: 5px;
        position: relative;
        &::before{
          content: "";
          height: 90%;
          
          background-color: #fff;
          left: 0;
          top: 0;
          position: absolute;
        }
        svg{
          margin-right: 7px;
          margin-top: 6px;
          margin-left: 15px;
          width: 20px;
          fill: #fff;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.newsletter-box{
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.newsletter-box img{
  width: 260px;
  height: 155px;
}

.quality{
  margin: 0 auto 20px;
  padding: 0 15px 1px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
  div{
    width: 100%;
    display: table;
    margin: 5px 0;
    padding: 3px 0;
    border-bottom: 1px solid #ddd;
    &:last-of-type{
      border-bottom: 0;
    }
    svg{
      vertical-align: middle;
      display: table-cell;
      fill:$color;
      width: 40px;
      height: 40px;
    }
    svg.q{
      margin-left: 5px;
      width: 32px;
    }
    span{
      text-transform: uppercase;
      font-family: "Titillium-bold", sans-serif;
      width: 79%;
      font-size: 14px;
      vertical-align: middle;
      display: table-cell;
    }
  }
}