.license-key-alert {
  width: 500px !important;
  background-color: #F4F4F2 !important;
  border: solid black 2px;

  button.confirm {
    background-color: #579532;
    width: 420px !important;
    height: 50px !important;
    margin: 0 !important;
    border-radius: 25px;
  }

  textarea.key-alert-key {
    background-color: #C3C3C4 !important;
    border: 2px inset ThreeDFace !important;
    padding: 15px 10px 15px 0 !important;
    margin: 30px auto 0 auto !important;
    width: 420px !important;
    //height: 50px !important;
    border-radius: 5px !important;
    display: block !important;
    font-size: 25px !important;
    text-align: center !important;
    color: black !important;
    resize: none;
  }
}
