body._activebody {
 overflow: hidden;
}
@media screen and (max-width: 648px) {
  body._activebody #tsbadgeResponsiveTop_db8d3657bdbe440c985ae127463eaad4{
    position: relative;
    z-index: 13;
    display: block !important;
  }
}

//-- ICON STYLE
.menu-icon {
  width: 40px;
  height: 44px;
  z-index: 11;
  cursor: pointer;
  position: relative;
  margin: 0 0 0 auto;
  transition: all 0.5s ease;
  @media screen and (max-width: 1024px) {
    width: 40px;
  }
}
.m_inner {
  display: block;
  top: 50%;
}
.m_inner, .m_inner::before, .m_inner::after {
  width: 40px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  &:hover{
    background-color: rgba(255,255,255,0.5);
  }
  @media screen and (max-width: 980px) {
    background-color: $color_2;
  }
}
.m_inner::before, .m_inner::after {
  content: "";
  display: block; }
.m_inner::before {
  top: -10px; }
.m_inner::after {
  bottom: -10px; }
.menu-icon .m_inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-icon .m_inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
.menu-icon .m_inner::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-icon.active .m_inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.menu-icon.active .m_inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
.menu-icon.active .m_inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

//--OVERLAY STYLE
.overlay-menu {
  background: #30303d url(images/dots-grid.png) center repeat;
  background-size: 30px 30px;
  color: #ffffff;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  transition: all 300ms;
  z-index: 9;
  .wrapper{
    height: 100%;
  }
  .logo{
    position: absolute;
    left: 0;
    top: 70px;
  }
  .menu-box{
    margin-top: 200px;
    width: 100%;
    position: absolute;
    right: 0;
    text-align: right;
    @media only screen and (max-width : 1400px) {
      margin-top: 170px;
    }
    @media only screen and (max-width : 980px) {
      margin-top: 80px;
    }
    ul{
      display: inline-block;
      width: 33%;
      vertical-align: top;
      padding-right: 2%;
      @media only screen and (max-width : 1400px) {
       padding-right: 40px;
      }
      .active{
        svg{
          background-color: $color;
        }
      }
      li {
        text-align: left;
        padding: 5px 0;
        a{
          display: inline-block;
          text-transform: uppercase;
          text-decoration: none;
          color: #eee;
          position: relative;
          font-family: "Titillium-bold", sans-serif;
          text-shadow: $shadow-fix;
          font-size: 15px;
          svg{
            width: 60px;
            height: 45px;
            margin-right: 8px;
            background: rgba(255,255,255,0.1);
            padding: 7px 5px;
            vertical-align: middle;
            fill:rgba(255,255,255,0.9);
            transition: $transition;
          }
          span{
            vertical-align: middle;
          }
          &:first-letter{
            font-size: 1.5em;
          }
          @media screen and (max-width: 1400px) {
            font-size: 14px;
            text-transform: inherit;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            &:hover {
              &::after {
                display: none;
              }
            }
            svg{
              width: 40px;
              height: 30px;
              padding:4px;
              flex-basis: 40px;
            }
          }
          @media screen and (max-width: 1400px) {
            svg{
            }
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            height: 1px;
            background: $color;
            transform: translateY(50%);
            right: 100%;
            width: 40px;
            opacity: 0;
            transition: right .3s ease-in-out, width .5s ease-out;
          }
          &:hover{
            opacity: 1;
            &::after {
              opacity: 1;
              right: -50px;
              width: 40px;
            }
          }
        }
        &:hover{
          svg{
            background-color: $color;
            fill: #fff;
          }
        }
      }
    }
  }

  .data{
    width: 100%;
    position: absolute;
    bottom: 30px;
    display: table;
    .company{
      display: table-cell;
      text-align: left;
      position: relative;
      font-size: 13px;
      color: white;
      span{
        opacity: 0.65;
      }
      span, a{
        display: block;
      }
      a{
        color: white;
        opacity: 0.65;
        text-decoration: none;
      }
      a:hover{
        opacity:1;
        text-decoration: underline;
      }
    }
  }
  .copyright{
    vertical-align: bottom;
    display: table-cell;
    text-align: right;
    font-size: 13px;
    span:first-of-type{
      opacity: 0.5;
    }
  }
}
.overlay-menu.open{
  visibility: visible;
  display: block;
  transform: scale(1);
  opacity: 1;
  ul{
    transform: translateY(-5%);
    opacity: 1;
  }
  ul.part1 {
    transition-delay: 0s;
  }
  ul.part2 {
    transition-delay: 0.35s;
  }
  ul.part3 {
    transition-delay: 0.7s;
  }
  @media screen and (max-width: 820px) {
    ul.part1, ul.part2, ul.part3 {
      transition-delay: 0s;
    }
  }
}
ul.part1, ul.part2, ul.part3 {
  transform: translateY(50%);
  opacity: 0;
  transition: all 1s;
}



//--MAKE THIS MENU RESPONSIVE

@media screen and (max-width: 1440px) {
  .overlay-menu {
    overflow-y: hidden;
    .menu-holder {
      margin-top: 90px;
    }
    .data{
      bottom: 15px;
    }
  }
}
@media screen and (max-width: 980px) {
  .overlay-menu {
    overflow-y: hidden;
    .logo{
    display: none;
    }
  }
  ._activebody {
    .top-line {
      z-index: inherit;
      background-color: #30303d;
      box-shadow: none;
      ul.top-right {
        li.basket, li.mobile-search {
          display: none;
        }
      }
    }
    .pre-header .logo{
      display: none;
    }
    .m_inner, .m_inner::before, .m_inner::after {
      background-color: #fff;
    }
  }
}

@media screen and (max-width: 820px) {
  .overlay-menu {
    overflow-y: scroll;
    background: #30303d;
    .data{
      display: none;
    }
    .wrapper{
      width: 100%;
    }
    .menu-box{
      margin-top: 28px;
      ul.part3{
        li:last-of-type{
          border-bottom: 0;
        }
      }
      ul {
        display: block;
        width: 100%;
        float: left;
        padding-right: 0;
        li{
          padding: 5px 0 5px 15px;
          border-bottom: 1px solid rgba(255,255,255,0.2);
          svg{
              width: 40px;
              flex-basis: 40px !important;
              height: 30px !important;
              padding:5px !important;
          }
          a{
            text-transform: uppercase;
            font-size: 14px;
            opacity: 1;
            &::after{
              display: none;
            }
          }
          &:hover{
            svg{
              fill:#fff;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .overlay-menu {
    .menu-box {
      //margin-top: 62px;
    }
  }
}

//chatbot balken
/*
.no_before._3L7815pw::before {
    display: none;
}
*/