header{
  background-color: #fff;
  position: relative;
}
.top-line{
  width: 100%;
  height: 50px;
  border-top:3px solid $font;
  background-color: $color;
  color: white;
  position: relative;
  z-index: 11;
  a{
    color: white;
    text-transform: uppercase;
    text-decoration: none;
  }
  a:hover{
    color: #000;
  }
  .service-line{
    display: table;
    float: left;
    text-align: left;
    padding: 4px 0 0 0 !important;
    svg._phone{
      width: 30px;
      height: 30px;
      display: table-cell;
      vertical-align: middle;
      margin-right: 12px;
    }
    div{
      display: table-cell;
      vertical-align: middle;
      ._s{
        font-size: 15px;
      }
      ._number{
        margin: -2px 0 0 0;
        text-align: left;
        display: inline-block;
        a{
          font-size: 15px;
        }
        span{
          margin-left: 3px;
          font-size: 12px;
          color: #000;
        }
      }
    }
  }
  .subheadline{
    float: left;
    padding: 0 0 0 100px;
    line-height: 3;
    font-family: 'Titillium', sans-serif;
    @media only screen and (max-width : 1400px) {
      padding: 0 0 0 45px;
      font-size: 15px;
      line-height: 3.2;
    }
    @media only screen and (max-width : 1024px) {
      display: none;
    }
  }
  ul.top-right{
    float: right;
    li{

      float: left;
    }
    li.vorlagen-rates{
      line-height: 2.8;
      svg {
        display: inline-block;
        width: 106px;
        height: 23px;
        vertical-align: middle;
        margin-right: 5px;
      }
      span{
        display: inline-block;
        vertical-align: middle;
        font-family: 'Titillium', sans-serif;
        font-size: 12px;
      }
      @media only screen and (max-width : 1400px) {
        display: none;
      }
    }
    li.mobile-menu{
      width: 55px;
      position: relative;
    }
    li.mobile-search{
      width: 55px;
      height: 70px;
      line-height: 3.5;
      display: none;
      cursor: pointer;
      vertical-align: middle;
      svg{
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        fill: #fff;
        @media only screen and (max-width : 980px) {
          fill: $font;
        }
      }
    }
    li.basket{
      position: relative;
      background-color:$font;
      text-align: right;
      width: 60px;
      height: 47px;
      z-index: 4;
      display: none;
      @media only screen and (max-width : 1024px) {
        display: block;
      }
      a{
        text-decoration: none;
      }
      svg.sbasket{
        width: 38px;
        display: block;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: -2px;
        bottom: 0;
        fill:#fff;
      }
      span{
        position: absolute;
        padding: 0;
        top: 5px;
        right: 8px;
        border-radius: 20px;
        width: 18px;
        height: 18px;
        padding-top: 2px;
        line-height: 1;
        text-align: center;
        color: #fff;
        background-color: $color;
        font-size: 14px;
        font-family: 'Titillium-bold', sans-serif;
     }
    }
  }
}
.basket-box:hover, .warenkorb:hover ~ .basket-box{
  max-height: 900px;
  box-shadow: $box-shadow;
  @media screen and (max-width: 980px) {
    max-height: 0;
    box-shadow: none;
  }
}

#au-partner-banner {
  padding: 10px;
  text-align:center;
  p {
    margin-bottom: 10px;
  }
}
.top-right {
  #login-icon{
    background-color: #ffffff;
    margin-right: 10px;
    img{
      margin-top: 5px;
      @media screen and (max-width: 375px){
        margin-top: 0;
      }
      width: 75%;
    }
  }
}
//--BASKET BOX
.basket-box{
  transition:all 0.45s ease-in-out;
  max-height: 0;
  z-index: 4;
  overflow: hidden;
  position: absolute;
  top:47px;
  right: 70px;
  width: 100%;
  max-width: 430px;
  padding: 0 20px;
  color: $font;
  font-size: 15px;
  background-color: white;
    table{
    margin: 7px 0;
    width: 100%;
    background-color: white;
    border-bottom: 2px solid $font;
    a{
    color: $font;
    text-transform: inherit;
      &:hover{
        color: $color;
        text-decoration: underline;
      }
    }
    th{
    text-transform: uppercase;
    padding: 5px 10px;
    font-family: 'Titillium-bold', sans-serif;
    font-size: 16px;
    text-align: left;
    border-bottom: 2px solid $font;
    }
    th:nth-of-type(3){
    text-align: right;
    }
    td{
    padding: 5px 10px;
    vertical-align: middle;
    }
    ._c1{
    text-align: center;
    width: 20px;
      svg{
        display: inline-block;
        vertical-align: middle;
        fill: black;
        width: 10px;
        height: 10px;
        transition: $transition;
        &:hover{
          fill:red;
        }
      }
    }
    ._c3{
    text-align: right;
    color: $color;
    font-size: 1.4em;
    font-family: 'Sarala-bold', sans-serif;
    width: 100px;
    padding: 5px 10px 5px 0;
    vertical-align: middle;
      span:nth-of-type(1){
        color: $color_3;
        font-family: 'Titillium-regular', sans-serif;
      }
    }
    tr{
    border-bottom: 1px solid #ccc;
    }
    ._gesamptpreis{
    background-color: #eaeaea;
    border-top: 1px solid $font;
        td{
          font-family: 'Sarala-bold', sans-serif;
          padding-bottom: 8px;
          span{
            font-weight: normal;
          }
        }
      }
    }
    .btn{
      margin: 10px 0 15px auto;
      }
    }
    .pre-header{
      width: 100%;
      padding: 20px 0;
      box-shadow: $box-shadow;
      position: relative;
      background-color: #fff;
        a{
        text-decoration: none;
        }
        ._mlogo{
        display: none !important;
        }
        .logo{
        width: 290px;
        height: 62px;
        display: inline-block;
        vertical-align: middle;
        }
        .basket-box{
        right: 0;
        top:67px;
        }
        .login-icon{
          width: 50px;
          height: 50px;
          margin-left: 16px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          img{
            width: 100%;
          }
          @media only screen and (max-width : 1024px) {
            display: none;
          }
        }
        .warenkorb{
        width:50px;
        height: 65px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        float: right;
          @media only screen and (max-width : 1024px) {
          display: none;
          }
        span{
        position: absolute;
        padding: 0;
        top: 5px;
        right: 2px;
        border-radius: 20px;
        width: 22px;
        height: 22px;
        line-height: 1.4;
        text-align: center;
        color: #fff;
        background-color: #579400;
        font-size: 14px;
        font-family: 'Titillium-bold', sans-serif;
        font-weight: bold;
          &:hover{
            color: #fff;
          }
      }
      svg{
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      width:40px;
      height: 40px;
      fill: #000;
      }
    }
  .trusted-shops{
    display: inline-block;
    vertical-align: middle;
    border: 2px solid $color;
    padding: 2px;
    border-radius: 100px;
    height: 60px;
    width: 60px;
      @media only screen and (max-width : 1024px) {
       margin-top: 2px;
       margin-right: 0;
        float: right;
      }
      @media only screen and (max-width : 667px) {
        display: none;
      }
  }
}
.web-form{
  margin: 0 30px;
  width: 745px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  //TWO MAIN BREAKING POINTS
  @media only screen and (max-width: 1400px){
    width: 465px;
    margin:0 20px;
  }
  @media only screen and (max-width : 1024px) {
    width: 59%;
    margin:0 0 0 15px;
  }
  @media only screen and (max-width : 980px) {
    margin: 0;
    width: 90%;
  }
  @media only screen and (max-width : 880px) {
    width: 89%;
  }
  @media only screen and (max-width : 780px) {
    width: 88%;
  }
  input{
    position: relative;
    float: left;
    width: 100%;
    border-radius: 50px;
    padding: 0 80px 0 30px;
    height: 60px;
    display: inline-block;
    border:1px solid rgba(0,0,0,0.35);
    font-size: 18px;
    text-align: center;
    &:focus{
      border:1px solid $color;
    }
  }
  i{
    display: none;
  }
  button{
    cursor: pointer;
    display: inline-block;
    border:1px solid #ccc;
    background-color: $background;
  }
  button.search{
    background-color:$color;
    z-index: 1;
    position: absolute;
    right: 5px;
    top:4px;
    height: 52px;
    width: 50px;
    overflow: hidden;
    border-radius: 50px;
    text-transform: uppercase;
    color: white;
    border: 1px solid $color;
    svg{
      width: 27px;
      height: 27px;
      fill:#fff;
      display: inline-block;
      vertical-align: middle;
    }
    &:hover{
      border: 1px solid $font;
    }
    &::after{
      position: absolute;
      content: '';
      top:0;
      left: 0;
      width: 0;
      height: 100%;
      background-color:$font;
      transform-origin:left;
      transition:width 0.35s ease;
      z-index:-1;
    }
    &:hover::after {
      width: 100%;
    }
  }
}
.mobile-form{
  display: none;
  background-color: #30303d;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  padding: 0 2% 0 2%;
  i{
    text-align: right;
    position: absolute;
    top: 15px;
    right: 2%;
    svg{
      cursor: pointer;
      width: 25px;
      height:25px;
      fill: #fff;
    }
  }
  form{
    position: absolute;
    top:35%;
    width: 96%;
    z-index: 1;
  }
  input{
    position: relative;
    color: #fff;
    font-size: 15px;
    width: 100%;
    padding: 0 40px 0 10px;
    border-top: 0;
    border-left: 0;
    border-right:0;
    border-bottom: 2px solid #ddd;
    background-color: transparent;
    display: table-cell;
    height:40px;
  }
  button.search{
    top:-10px;
    bottom: 0;
    margin: auto;
    background-color: transparent;
    border: 0;
    right: -5px;
    z-index: 1;
    position: absolute;
    svg{
      width: 30px;
      height: 30px;
      fill: $color;
    }
    &:hover{
      border: 0;
    }
    &:hover::after {
      width: 0;
    }
  }
}


.menu-nav{
  ul{
    display: inline-block;
    width: 100%;
    li{
      font-size: 15px;
      padding: 2px 0;
      a{
        color: $font;
        &:hover{
          color: $color;
          text-decoration: underline;
        }
        &::before{
          content: "";
          display: inline-block;
          margin-top: 0;
          margin-right: 6px;
          width: 5px;
          height: 5px;
          vertical-align: middle;
          background-color: $color;
        }
      }
    }
  }
}

.menu-header{
  width: 100%;
  background: $font;
  height: 30px;
  margin-bottom: 15px;
  overflow: hidden;
  .startseite-holder{
    float: right;
  }
  .top-left{
    width: 100%;
    text-align: left;
    li{
      font-family: "Sarala", sans-serif;
      width: auto;
      padding: 3px 0;
      display: inline-block;
      a{
        text-shadow: $shadow-fix;
        text-decoration: none;
        color: white;
        font-size: 14px;
        display: inline-block;
        &:hover{
          color: $color;
        }
      }
    }
    li::after{
      content: "|";
      margin: 0 3px 0 6px;
      color: rgba(255,255,255,0.5);
    }
    li:last-of-type::after{
      display: none;
    }
  }
}


//--MAKE THIS HEADER RESPONSIVE

@media only screen and (max-width : 980px) {
  .top-line{
    height: 60px;
    margin-bottom: 0;
    overflow: hidden;
    background-color: #fff;
    box-shadow: $box-shadow;
    .service-line{
      display: none;
    }
    ul.top-right{
      li.mobile-search {
        width: 40px;
        svg{
          width: 25px;
          height: 25px;
        }
      }
      li.basket {
      text-align: right;
      width: 60px;
      height: 57px;
        svg.sbasket {
          top: -2px;
          left: -4px;
          height: 40px;
        }
        span {
          padding-top: 2px;
          line-height: 1;
        }
      }
      li.mobile-menu {
        margin-top: 5px;
      }
    }
  }
  .basket-box{
    right: 60px;
  }
  .pre-header{
    margin-top: 0;
    padding: 15px 0 20px 0;
    .logo{
      width: 211px;
      height: 45px;
      display: block;
      position: absolute;
      z-index: 11;
      margin-top: -66px;
      margin-left:0;

    }
  }
}

@media only screen and (max-width : 667px) {
  /*.top-line{
    ul.top-right {
      li.mobile-search {
        display: inline-block;
      }
    }
  }*/
  .pre-header{
    padding:0;
    box-shadow: none;
    .logo{
      width: 160px;
      height: auto;
      margin-top: -45px !important;
    }
  } 
  .web-form{
    /*display: none;*/
    width: 100%;
    input {
      height: 50px;
      margin: 5px 0 5px 0;
    }
    button.search{
      right: 3px;
      top: 8px;
      height: 44px;
      width: 42px;
    }
  }
  .basket-box{
    display: none;
  }
  .menu-header{
    display: none;
  }
}
@media only screen and (max-width : 440px) {
  .pre-header{
    .logo {
      margin-top: -40px !important;
    }
  }
  .top-line{
    height: 50px;
    ul.top-right {
      li.mobile-search {
      width: 40px;
      height: 47px;
     line-height: 3;
        svg{
          width: 25px;
          height: 25px;
        }
      }
      li.basket{
        height: 47px;
      }
      li.mobile-menu{
        margin-top: 2px;
        width: 50px;
      }
    }
  }
}

@media only screen and (max-width : 380px) {
  .pre-header {
    .logo {
      width: 155px;
    }
  }
}

@media only screen and (max-width : 330px) {
  .pre-header {
    .logo {
      margin-top: -37px !important;
      width: 125px;
    }
  }
}

body.overlay-search::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  animation: SHW .5s ease;
}
body.overlay-search .web-form{
  z-index: 111;
}
@keyframes SHW {
  from {
    transform:scale(0.95);
    opacity:0;
  }
  to {
    transform: scale(1);
    opacity:1;
  }
}
