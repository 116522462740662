$font:#3c424e;
$color: #579400;
$color_white: #FFFFFF;
$color_2:rgba(16, 16, 16, 1);
$colorbuy:#f06937;
$colorbuyhover:#f89406;
$color_3:#32383e;
$background:#f4f4f2;
$box-shadow:0 1px 3px rgba(0,0,0,0.2);
$link:#002a8d;
$transition:all 0.25s ease-in-out;
$transition-duration: 0.5s;
$items: 4;
$transition-delay: 0.05s;
$shadow-fix: 1px 1px 1px rgba(0,0,0,1);
