.indicator{
  background-color: #fff;
  box-shadow: $box-shadow;
  padding: 20px 0 30px 0;
  @media only screen and (max-width: 490px){
    display: none;
  }
  li.active{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: auto;
      bottom: -40px;
      left: 0;
      right: 0;
      margin: auto;
      background: url('images/svg/basket-shop.svg') no-repeat center top;
      background-size: 40px;
      width: 40px;
      height: 40px;
    }
  }
}
.btn-right{
  text-transform: uppercase;
  position: absolute;
  height: 44px;
  top: 20px;
  right: 20px;
  padding: 0;
  line-height: 2.8;
  width: 180px;
  font-size: 15px;
  font-family: "Sarala-bold", sans-serif;
  border: 1px solid $colorbuyhover;
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  background-color: #faa732;
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  &::after{
    background-color: $colorbuyhover;
  }
}
table.warenkorb{
  width: 100%;
  tr{
    &:first-of-type{
      border-top: 2px solid $font;
    }
    &:last-of-type{
      border-bottom: 2px solid $font;
    }
  }
  td{
    padding: 10px 15px;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
    position: relative;
    &:last-of-type{
      text-align: right;
    }
  }
  .media {
    display: table-cell;
    vertical-align: top;
    width: 55px;
    padding-right: 15px;
    img {
      width: 100%;
    }
  }
  .discount_row{
    background-color:whitesmoke;
  }
  .cont{
    display: table-cell;
    vertical-align: top;
  }
  h2{
    padding: 0;
    font-size: 1.3em;
    line-height: 1.2;
    a{
      color:$font;
      text-decoration: none;
      &:hover{
        color: $color;
      }
    }
  }
  .s_download{
    display: block;
    margin: 3px 0 2px;
    font-size: 15px;
  }
  .sofort_download{
    font-family: "Sarala-bold", sans-serif;
    color: #000;
  }
  .entfernen{
    text-decoration: none;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
    svg{
      display: inline-block;
      margin-right: 5px;
      width: 9px;
      height: 9px;
      transition: $transition;
      &:hover{
        fill: red;
      }
     }
    &:hover{
      color: red;
    }
    &:hover svg{
      fill: red;
    }
  }
}
._preis{
  width: 160px;
  margin: 0 0 0 auto;
  font-size: 2.4em;
  font-family: "Sarala-bold", sans-serif;
  color: $color;
  span:nth-of-type(1){
    color: $font;
    font-family: "Titillium-regular", sans-serif;
  }
}

.payments__process{
  display: table;
  width: 100%;
}
.gutschein, .total{
  margin-top: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  vertical-align: top;
  background-color: whitesmoke;
}
.custom-error-container {
    padding: 12px 0 0 0;
    .icon {
      background-color: #ea7d7d;
      border-radius: 50%;
      color: white;
      display: inline-block;
      height: 24px;
      line-height: 24px;
      margin-right: 3px;
      text-align: center;
      width: 24px;
    }
    p {
      color: #c60000;
      display: inline-block;
    }
}
.keine-produkte{
  h1{
    text-align: center;
  }
    .shop_cart{
      background: url('images/no-results.jpg') center no-repeat;
      background-size: 150px;
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
  .btn{
    font-size: 18px;
    padding: 0 20px 0 30px;
  }
}

.gutschein{
  padding: 15px;
  float: left;
  width: 39%;
   margin-right: 1%;
  form{
    width: 100%;
    input, button{
      margin-top: 0 !important;
      height: 45px;
      display: inline-block;
      vertical-align: top;
    }
    input.inpt{
      width: 66%;
      padding-left: 10px;
      margin-right: 0.5%;
      &:focus{
        border:1px solid $color;
      }
    }
    button.btn{
      min-width: 100px;
      width: 32%;
      float: right;
      padding: 0;
      border: 0;
      cursor: pointer;
      position: relative;
      margin: 0;
      text-transform: uppercase;
      &::after{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $color;
        transform-origin: left;
        transition: width 0.35s ease;
        z-index: -1;
      }
      &:hover::after{
        width: 100%;
      }
    }
    @media only screen and (max-width: 450px){
      input.inpt {
        width: 65%;
      }
      button.btn {
        min-width: 80px;
        font-family: "Sarala",sans-serif;
        font-size: 13px;
      }
    }
  }
  @media only screen and (max-width: 980px){
    width: 100%;
    margin-right: 0;
  }
}
.borderbottom{
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 2px;
}
.total{
  padding: 5px 15px 10px;
  width: 38%;
  margin-left: 2%;
  float: right;
  .gesamtpreis{
    text-transform: uppercase;
    display: table;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 2px;
  }
  ._total-cell{
    vertical-align: middle;
    display: table-cell;
  }
  ._gtxt{
    font-size: 1.65em;
    font-family: "Titillium-bold", sans-serif;
      span{
        text-transform: initial;
        font-size: 14px;
        display: block;
        font-family: "Sarala", sans-serif;
      }
  }
  ._preis{
    text-align: right;
  }
  .pay-btns {
    //padding-top: 5px;
    .ssl{
      color:#000;
      font-size: 14px;
      text-transform: uppercase;
    }
    .btn-paypal {
      font-size: 14px;
      margin: 5px 0;
      color:#003087;
    }
    .btn{
      line-height: 2.9;
      text-transform: uppercase;
      padding: 0 0 2px 0;
      width: 100%;
      height: 50px;
      font-family: "Sarala-bold", sans-serif;
      margin: 5px 0;
      font-size: 16px;
      border: 1px solid $colorbuyhover;
      text-shadow: 0 1px 1px rgba(0,0,0,0.5);
      background-color: #faa732;
      background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
      &::after{
        background-color: $colorbuyhover;
      }
      span{
         display:inherit;
      }
    }
     .btn-paypal {
      display: block;
      width: 100%;
      height: 55px;
       line-height: 1.75;
    }
  }
  @media only screen and (max-width: 980px){
    width: 100%;
    margin-left: 0;
    padding: 5px 15px;
  }
}

.down-butns{
    margin: 40px 0 0;
    width: 100%;
}
.btn-zuruck{
  display: inline-block;
  width:230px;
  text-align: center;
  margin-top: 20px;
  height: 45px;
  text-decoration: none;
  color:$font;
  line-height: 3.3;
  font-family: "Sarala-bold", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  background-color: #fff;
  border: 1px solid $font;
  z-index: 1;
  &:hover{
    color:#fff;
  }
  &::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $font;
    transform-origin: left;
    transition: width 0.35s ease;
    z-index: -1;
  }
  &:hover::after{
    width: 100%;
  }
}
.zahlungsarten{
  display: inline-block;
  float: right;
  text-align: right;
    span{
      font-family: "Sarala-bold", sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      display: block;
      margin-bottom: 5px;
    }
    svg{
      margin: 0 auto;
      display: inline-block;
      width: 62px;
      height: 35px;
      padding: 3px 3px;
      border-radius: 4px;
      border: 1px solid #ddd;
      background-color: whitesmoke;
    }
}

//--MAKE THIS SITE RESPONSIVE
@media only screen and (max-width: 667px){
  .payment-gateways{
    padding-top: 10px;
    padding-bottom: 30px;
    h1{
      padding: 0 0 10px;
      font-size: 1.45em;
    }
    h2{
      padding: 0 0 10px;
      font-size: 1.2em;
    }
  }
  .btn-right{
    display: none;
  }
  table.warenkorb {
    td {
      padding: 5px;
    }
    h2 {
    font-size: 1.1em;
      a {
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
      }
    }
  }
  ._preis{
    font-size: 2em;
    width: 130px;
    sup{
      font-size: 25px;
    }
  }
  .total {
    ._gtxt {
      font-size: 1.25em
    }
  }

}
@media only screen and (max-width: 580px){
  .down-butns{
    display: none;
  }
  table.warenkorb {
    .media{
      display: none;
    }
  }
}
@media only screen and (max-width: 480px){
  .payment-gateways{
    h1{
      padding: 0 0 10px;
    }
  }
  .down-butns{
    display: none;
  }
  table.warenkorb {
    h2 {
      font-size: 17px;
    }
    span.sofort_download {
      font-size: 14px;
    }
    .s_download{
      font-size: 12px;
    }
  }
  ._preis{
    font-size: 1.5em;
    width: 105px;
  }
}