@font-face {
  font-family: 'Sarala';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Sarala/Sarala-Regular.eot');
  src: url('fonts/Sarala/Sarala-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Sarala/Sarala-Regular.woff') format('woff'),
  url('fonts/Sarala/Sarala-Regular.ttf') format('truetype'),
  url('fonts/Sarala/Sarala-Regular.svg#genericonsregular') format('svg');
}

@font-face {
  font-family: 'Sarala-bold';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Sarala/Sarala-Bold.eot');
  src: url('fonts/Sarala/Sarala-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Sarala/Sarala-Bold.woff') format('woff'),
  url('fonts/Sarala/Sarala-Bold.woff2') format('woff2'),
  url('fonts/Sarala/Sarala-Bold.ttf') format('truetype'),
  url('fonts/Sarala/Sarala-Bold.svg#genericonsregular') format('svg');
}

@font-face {
  font-family: 'Titillium-bold';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Titillium/TitilliumWeb-SemiBold.eot');
  src: url('fonts/Titillium/TitilliumWeb-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Titillium/TitilliumWeb-SemiBold.woff') format('woff'),
  url('fonts/Titillium/TitilliumWeb-SemiBold.woff2') format('woff2'),
  url('fonts/Titillium/TitilliumWeb-SemiBold.ttf') format('truetype'),
  url('fonts/Titillium/TitilliumWeb-SemiBold.svg#genericonsregular') format('svg');
}

@font-face {
  font-family: 'Titillium-regular';
  font-weight:normal;
  font-style: normal;
  src: url('fonts/Titillium/TitilliumWeb-Regular.eot');
  src: url('fonts/Titillium/TitilliumWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Titillium/TitilliumWeb-Regular.woff') format('woff'),
  url('fonts/Titillium/TitilliumWeb-Regular.ttf') format('truetype'),
  url('fonts/Titillium/TitilliumWeb-Regular.svg#genericonsregular') format('svg');
}


