#login-content{
  #logout {
    float:right;
  }
  padding-top: 15px;
  .alert{
    color: #53ce34;
  }
  h1 {
    @media screen and (max-width: 545px) {
      font-size: 1.5em;
    }
  }
  .main-section{
    width: 100%;
    padding-bottom: 35px;
    text-align: center;
    margin: 0 auto !important;
    table{
      border-spacing: 235px 100px;
      border-collapse: unset;
      @media screen and (max-width: 1423px) {
        border-spacing: 210px 100px;
      }
      @media screen and (max-width: 1349px) {
        border-spacing: 180px 100px;
      }
      @media screen and (max-width: 750px) {
        border-spacing: 150px 100px;
      }
      @media screen and (max-width: 545px) {
        border-spacing: 110px 100px;
      }
      @media screen and (max-width: 375px) {
        border-spacing: 45px 100px;
      }
    }


    td{
      a{
        width: 100%;
        text-decoration: none;
      }
      padding: 15px 0px 15px 0px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

      @media screen and (max-width: 980px) {
        padding: 10px;
        #profile-img{
          margin-top: 20px;
        }
      }
    }
    img{
      width: 50%;
    }
  }
  #order-download-btn {
    padding: 0;
  }
  #order-table {
    @media screen and (max-width: 980px) {
      min-width: 357px;
    }
    a{
      color: #ffffff;
    }
  }
}

.controls {
  #email{
    padding: 5px 0 5px 6px;
    width: 50%;
  }
  #password{
    padding: 5px 0 5px 6px;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 7px;
  }
}

.dropdown {
  float: right;
  overflow: hidden;
  span{
    margin-left: 35px;
  }
  i{
    margin-left: 35px;
  }
  margin-top: 25px;
  @media screen and (min-width: 509px){
    margin-top: 15px;
  }
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 14px 16px;
  background-color: #3c424e;
  font-family: inherit;
  margin: 0;
  border-radius: 12px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 12px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-radius: 12px;
}

.topnav a:hover, .dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.form_profile{
  //width: 760px;
  font-size: 14px;
  input, select{
    border:1px solid rgba(0,0,0,0.15);
  }
  .info{
    margin: 5px 0 10px;
    font-family: "Sarala-bold", sans-serif;
    color: #000;
    font-size: 13px
  }
  .control-group{
    padding: 5px 0;
    label{
      color: #000;
      text-transform: uppercase;
      margin-bottom: 2px;
      display: inline-block;
    }
    .radio.inline{
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .controls{
    .fa-check{
      top: 10px;
    }
    .fa-times {
      top: 19px;
    }
  }
  .row-fluid {
    .span6 {
      width: 50%;
      float: left;
      &:nth-of-type(1){
        margin-right: 2%;
        width: 48%;
      }
    }
    .span4{
      width: 32%;
      float: left;
    }
    .span8{
      width: 66%;
      float: left;
      margin: 0 !important;
    }
  }
  select#country{
    margin: 10px 0;
    padding: 12px 0;
    background-color: white;
    width: 100%;
  }

  select#country_2{
    margin: 10px 0;
    padding: 12px 0;
    background-color: white;
    width: 100%;
  }
  select#sector{
    padding: 12px 0;
    background-color: white;
    width: 100%;
    font-family: "Sarala",​sans-serif;
  }
  .btn{
    text-transform: uppercase;
    height: 46px;
    width: 50%;
    margin: 5px 0 20px auto;
    font-size: 16px;
  }
  ._required{
    color: red;
    padding-left: 3px;
  }
}