.mobile-sidebar{
  display: none !important;
}
@media only screen and (max-width : 1024px) {
  .mobile-sidebar{
    display: inline-block !important;
  }
  .web-sidebar{
    display: none !important;
  }
}
//--LEFT SIDEBAR -> PRODUKTVORSCHAU + WEITERE PRODUKTE
.left-sidebar{
  position: relative;
  vertical-align: top;
  float: left;
  display: inline-block;
  width: 220px;
  .product-gallery{
    border-top:2px solid $font;
    overflow: hidden;
    text-align:center;
    box-shadow: $box-shadow;
    background: white;
    padding: 0 10px 5px 10px;
    margin-bottom: 15px;
    min-height: 349px;
    h5{
      margin-bottom: 15px;
    }
    //--ELEVATE ZOOM WITH PRODUCT SLIDER
    .frame{
      border:1px solid #ccc;
      padding: 10px 9px 5px;
      margin-bottom: 3px;
      position: relative;
      &::after{
        content: "";
        background: rgba(255,255,255,1) url('images/svg/zoom.svg') no-repeat center;
        background-size: 25px;
        border-radius: 30px;
        width: 45px;
        height: 45px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .frame.hideglas{
      &::after{
        content: "";
        background: none;
      }
    }
    .zoom_01{
      position: relative;
    }
    #prev_mc{
      left: 20px;
    }
    #next_mc{
      right: 20px;
    }
    #prev_mc, #next_mc{
      display:none;
      top:-10px;
      bottom: 0;
      height: 35px;
      margin: auto;
      position: absolute;
      .sa{
        transition: $transition;
        width:30px;
        height:30px;
        &:hover{
          background-color: $color;
        }
      }
    }
    #gallery_01 {
      text-align: left;
      padding-top:2px;
      margin-right: -9px;
      margin-left: 0;
      li{
        display: inline-block;
        width: 31%;
        vertical-align: top;
      }
      a{
        text-decoration: none;
      }
      img {
        vertical-align: top;
        border:1px solid #ccc;
        width: 63px;
        margin-bottom: 4px;
      }
    }
    .active {
      img {
        border: 1px solid $font !important;
      }
    }
  }
  .additional-left{
    float: left;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    background: white;
    width: 100%;
    padding: 0;
    border-top: 2px solid $font;
    h6{
      color: white;
      background-color: $font;
      text-transform: uppercase;
      padding: 2px 10px;
      font-size: 11px;
      display: table;
      position: relative;
      margin-bottom: 5px;
      font-family: "Sarala-bold";
      &::after{
        content: "";
        position: absolute;
        vertical-align: middle;
        width: 0;
        height: 0;
        top: 0;
        right: -15px;
        border-top: 19px solid #3c424e;
        border-bottom: 20px solid transparent;
        border-right: 15px solid transparent;
      }
    }
    a{
      text-decoration: none;
      color: $font;
      &:hover{
        color: $color;
      }
    }
    article{
      padding: 7px 5px 10px 10px;
      transition: $transition;
      position: relative;
      display: table;
      z-index: 1;
      &::after{
        content: "";
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        top: 100%;
      }
      &:last-of-type::after{
       display: none;
      }
      a{
        display: table-row;
      }
      svg{
        fill: $font;
        width: 25px;
        text-align: left;
        display: table-cell;
        margin: 3px 5px 0 0;

      }
      p{
        font-size: 14px;
        display: table-cell;
        vertical-align: top;
      }
      &:hover{
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0,0,0,0.35);
      }
    }
  }
}
//gallery dynamic preview
#dynamic_img_box_content {
  position: absolute;
  width: 179px;
  z-index: 1;
  height: 257px;
  transform: matrix3d(1, 0, 0.00, 0.0006, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transform: matrix3d(1, 0, 0.00, 0.0006, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

#dynamic_img_box_head {
  font-family: Sarala;
  text-align: left;
  margin: 35px 20px 0 28px;
  float: left;
  color: white;
  width: 90px;
  height: 40px;
  font-size: 12px;
  line-height: 18px;
  word-wrap: break-word
}

#dynamic_img_box_head.mobile {
  height: 35px;
  width: 80px;
  font-size: 11px;
  line-height: 16px;
  margin: 30px 20px 0 24px;
}