.wrapper{
  position: relative;
  margin: 0 auto;
  width: 1300px;
  padding: 0;
  @media only screen and (max-width : 1400px) {
    width: 1000px;
  }
  @media only screen and (max-width : 1024px) {
    width: 96%;
  }

}

// -- LOAD PAGE WITH ANIMATION
@-webkit-keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}
.loadPage {
  -webkit-animation-name: fadeInPage;
  animation-name: fadeInPage;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

// -- BREADCRUMBS
.breadcrumb {
  padding: 2px 0 0;
  width: 100%;
  text-align: left;
  .divider{
    color: $color;
    margin: 0 3px 0 0;
    font-family: "Sarala-bold", sans-serif;
  }
  li{
    font-family: "Sarala", sans-serif;
    width: auto;
    padding: 1px 0;
    display: inline-block;
	&:nth-of-type(1){
		a{
		  float: left;
		}
	  }
    a{
	  text-decoration: none;
	  color: white;
	  font-size: 13px;
	  display: inline-block;
      text-shadow: $shadow-fix;
	  &:hover{
	    color: $color;
	  }
	  svg{
	    height: 30px;
		margin-right: 4px;
		margin-top:2px;
		width: 22px;
	  }
    }
  }

  li:last-of-type::after{
    display: none;
  }
}

.btn{
  border: none;
  text-align: center;
  position: relative;
  display: table;
  margin: 20px auto;
  padding: 0 10px 0 15px;
  height: 35px;
  background-color: $font;
  color: white;
  z-index: 0;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-family: "Sarala-bold", sans-serif;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.75);
  line-height: 2.3;
  min-width: 170px;
  &:first-letter{
    text-transform: uppercase;
  }
  span{
    display: inline-block;
    color:#fff;
    &:first-letter{
      text-transform: uppercase;
    }
  }
  .arrow{
    vertical-align: middle;
    display: inline-block;
    width: 29px;
    margin-left: 9px;
    height: 9px;
  }
  &:hover{
    color: white !important;
  }
  &::after{
    position: absolute;
    content: '';
    top:0;
    left: 0;
    width: 0;
    height: 100%;
    background-color:$color;
    transform-origin:left;
    transition:width 0.35s ease;
    z-index:-1;
  }
  &:hover::after {
    width: 100%;
  }
}
.btn-kostenlos{
  padding: 0;
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  background-color: #f89406;
  text-transform: uppercase;
  height: 45px;
  line-height: 1.15;
  padding-top: 5px;
}
.simplebtn{
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
  background-color: $color;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;
}