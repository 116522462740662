.page{
  .left-category-sidebar{
    margin-top: 0;
    margin-left: 0;
    float: left;
    @media only screen and (max-width : 1400px) {
      width: 290px;
    }
  }
  .listing{
    box-shadow: $box-shadow;
    display: inline-block;
    background-color: #fff;
    vertical-align: top;
    width: 980px;
    padding: 0 20px 10px 20px;
    position: relative;
      @media only screen and (max-width : 1400px) {
        width: 690px;
      }
      @media only screen and (max-width : 1024px) {
        width: 100%;
      }
      @media only screen and (max-width : 667px) {
        box-shadow: none;
        margin-left: -2%;
        width: 104%;
        background-color: whitesmoke;
        padding: 0 2% 10px 2%;
      }
    h1{
      padding: 1em 0;
      width: 100%;
    }
    h2.search{
      padding-top:0;
      padding-bottom: 20px;
    }
    .search{
      display: inline-block;
      font-size: 1.35em;
      span{
        font-size: 70%;
        vertical-align: middle;
		&:nth-of-type(2){
		  float: right;
		  margin-top: 6px;
        }
      }
    }
    .list-spacer{
      display: inline-block;
      width: 170px;
      @media only screen and (max-width : 667px) {
        display: block;
        margin-bottom: 3px;
      }
    }
    @media only screen and (max-width : 667px) {
      .search{
        font-size: 1.3em;
        padding: 10px 0 13px;
      }
      h2.search{
        margin-top: -8px;
        padding-bottom: 10px;
        display: inline-block;
      }
    }
    .q__cat{
        background-color: $background;
        border:1px solid rgba(0, 0, 0, 0.25);
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        padding:5px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        font-family: "Titillium-bold", sans-serif;
        font-size: 16px;
        line-height: 1;
      svg{
        cursor: pointer;
        margin-left: 10px;
        width: 12px;
        height: 12px;
        fill: #000;
        transition: $transition;
        &:hover{
          fill: red;
        }
      }
        @media only screen and (max-width : 667px) {
          background-color: #fff;
          margin: 0 4px 4px 0;
        }
    }
    .search-query{
      display: inline-block;
      vertical-align: middle;
      @media only screen and (max-width : 667px) {
        margin-bottom: 0;
      }
      div{
        background-color: $background;
        border:1px solid rgba(0, 0, 0, 0.25);
        display: inline-block;
        margin: 0 10px 3px 5px;
        text-transform: uppercase;
        font-family: "Titillium-bold", sans-serif;
        padding:5px 15px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 1;
        @media only screen and (max-width : 667px) {
          background-color: #fff;
          margin-left: 0;
        }
        span{
          line-height: 1;
          width: 20px;
          height:12px;
        }
        svg{
          cursor: pointer;
          margin-left: 10px;
          width: 12px;
          height: 12px;
          fill: #000;
          transition: $transition;
          &:hover{
            fill: red;
            + .q{
              border: 1px solid red;
            }
          }
        }
      }
    }
    .category-listing{
      a{
        text-decoration: none;
      }
      article{
        border: 1px solid rgba(0,0,0,0.2);
        margin-bottom: 12px;
        transition: $transition;
        position: relative;
        overflow: hidden;
        min-height: 120px;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width : 667px) {
          min-height: 90px;
          text-align: center;
        }
        &:hover{
          border: 1px solid #000;
          .btn::after{
            width: 100%;
          }
        }
      }
      ._l-column{
        vertical-align: top;
        padding: 7px 0 7px 15px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
         .media{
           flex: 0 0 100px;
          vertical-align: top;
          padding-right: 10px;
          img{
            width: 100%;
          }
        }
        .content{
          padding-right: 10px;
          h2{
            transition: $transition;
            color: $font;
            padding: 0 0 0.3em 0;
            font-size: 1.35em;
            line-height: 1.2;
          }
          div{
            font-size: 15px;
            color: $font;
          }
        }
        .media-icon{
         padding-top: 3px;
          flex: 0 0 52px;
        }
      }
      ._r-column{
        border-left: 1px solid rgba(0,0,0,0.1);
        background-color: $background;
        flex: 0 0 220px;
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 7px 0 7px 0;
        word-break: break-word;
        .r-holder{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
        }
        .discount{
          margin: 0 15px 0 -15px;
          width: 50px;
          height: 46px;
          font-size: 1.75em;
          z-index: 11;
          line-height: 1.8;
        }
        .special-prize{
          margin: 0;
        }
        .price-holder{
          display: flex;
          flex-direction: row;
        }
        .btn{
          margin: 8px auto 0;
          display: block;
          width: 160px;
        }
        .new-price{
          margin-top: -12px;
          @media only screen and (max-width : 667px) {
            margin-top: -5px;
          }
          span{
            font-size: 2em;
          }
        }
        .old-price{
          font-size: 1em;
        }
      }
      @media only screen and (max-width : 1400px) {
        ._r-column {
          width: 200px;
          .btn {
            width: 160px;
          }
          .discount {
            width: 44px;
            height: 44px;
            margin-right: 8px;
            font-size: 1.5em;
            line-height: 2;
          }
        }
      }
      @media only screen and (max-width : 667px) {
        article{
          background-color: #fff;
          margin-bottom: 15px;
          border: 1px solid #fff;
          box-shadow: $box-shadow;
          flex-direction: column;
        }
        ._l-column{
          display: flex;
          width: 100%;
        }
        ._r-column{
          display: flex;
          flex: 0;
          width: 94%;
          padding-bottom: 8px;
          margin: 0 auto 8px;
          border: 1px solid rgba(0,0,0,0.1);
          .r-holder{
            position: relative;
            top: 0;
            text-align: center;
            height: auto;
          }
          .offer{
            margin-top: 0;
          }
          .discount{
            margin-top: 5px;
            height: 40px;
            width: 55px;
            line-height: 1.8;
            font-size: 1.5em;
          }
          .old-price{
            margin-bottom: -10px;
          }
          .btn{
            width: 170px;
            margin: 0 auto 3px auto;
            position: relative;
            bottom: 0;
           }
        }
      }
      @media only screen and (max-width : 460px) {
        ._l-column{
          .media{
            width: 55px;
          }
          .content h2{
            font-size: 1.15em;
          }
        }
        ._r-column {
          .new-price{
            padding-top: 0;
            span {
              font-size: 2em;
            }
          }
          .price-holder {
            display: block;
            width: 100%;
          }
          .btn{
            width: 185px;
          }
        }
      }
      @media only screen and (max-width : 420px) {
        ._l-column {
          .content {
            h2, div {
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}

.r-holder.offer{
  //height: 95px !important;
  @media only screen and (max-width : 667px) {
    height: auto !important;
  }
}

.hitcounter{
  margin-top: 20px;
  margin-bottom: -10px;
}
.pagination{
  text-align: center;
  margin: 30px 0;
  font-size: 17px;
  display: block;
  width: 100%;
  .active {
    padding: 2px 12px;
    color: white;
    background-color: $color;
    border: 1px solid $color;
    margin: 0 2px;
  }
  li.disabled {
    &:hover {
      border: 1px solid white;
    }
    span{
      color:grey;
      }
  }
  li.next{
    span{
      letter-spacing: -1.93px;
    }
  }

  li{
    display: inline-block;
    border: 1px solid transparent;
    margin: 0 2px 15px 2px;
    transition: $transition;
    a{
      display: inline-block;
      padding: 2px 10px;
      text-decoration: none;
      color: $font;
    }
    &:hover {
      a{
        color: $color;
      }
        border: 1px solid grey;
    }
  }
}
.no-results{
  p{
    margin: 10px 0;
  }
    ul{
      margin: 20px 0 40px 20px;
      li{
        float: left;
        width: 33%;
        @media only screen and (max-width : 1000px) {
          width: 49%;

        }
        @media only screen and (max-width : 667px) {
          width: 100%;
        }
      }
    }
  @media only screen and (max-width : 667px) {
    background-color:#fff;
    width: 104%;
    margin:0 0 5px -2%;
    padding: 5px 20px 5px 20px;
    .q__cat{
      background-color: $background !important;
    }
  }
}
.additional-notes{
    box-shadow: $box-shadow;
    background-color: #fff;
    vertical-align: top;
    width: 980px;
    margin: 20px 0 20px auto;
    padding: 10px 20px 20px 20px;
    line-height: 1.6;
    @media only screen and (max-width : 1400px) {
      width: 690px;
    }
    @media only screen and (max-width : 1024px) {
      width: 100%;
    }
    @media only screen and (max-width : 667px) {
      margin-top: 0;
      box-shadow: none;
      margin-left: -2%;
      width: 104%;
    }
}



