.productcampaign-container {
    .product-list-container {
        overflow: auto;
    }
    .product-list {
        margin-left: auto;
        margin-right: auto;
        width: 1050px;
        padding: 0;
        overflow: auto;
        height: 100%;
        list-style: none;
        li {
            &::before {
                display: none;
            }
            background-color: white;
            border: 1px solid rgba(0,0,0,0.15);
            width: 340px;
            height: 416px;
            margin: 0 5px 30px;
            position: relative;
            float: left;
        }

        a {
            text-decoration: none;
            color: #3c424e;
            display: block;
        }

        h2 {
            font-size: 1.25em;
            height: 78px;
            line-height: 1.2;
            text-align: center;
            overflow: hidden;
            padding: 10px 3% 15px 3%;
        }

        @media only screen and (min-width: 1400px) {
            .frame-slider ul .icon-side {
                width: 60px;
            }
        }

        .icon-side {
            display: inline-block;
            width: 60px;
            vertical-align: top;
        }

        .excerpt-side {
            display: inline-block;
            width: 175px;
            vertical-align: top;
            text-align: center;
            position: relative;
            font-size: 14px;
            color: #3c424e;
            max-height: 152px;
            overflow: hidden;

            img {
                max-height: 150px;
                max-width: 173px;
                box-shadow: 6px 6px 8px -7px rgba(0,0,0,0.35);
            }
        }

        .actual-price, .previous-price {
            display: block;
        }

        .actual-price {
            width: 100%;
            color: #579400;
            position: absolute;
            bottom: 105px;
            right: 0;
            left: 0;
            text-align: center;
        }

        .previous-price {
            color: grey;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            position: absolute;
            bottom: 144px;
            font-family: 'Titillium-bold', sans-serif;
            text-decoration: line-through;
            text-shadow: 1px -1px 0 white;
            width: 140px;
        }

        .product-button-group {
            margin-top: 20px;
            position: absolute;
            bottom: 0;
            width: 100%;
            .btn {
                width: 100%;
                margin: 0;
                margin-bottom: 5px;
            }
        }       
    }
}
.campaign-container{
    .page-content{
        > img {
            width: 100%;
        }
        padding: 0 0 30px 0;
        text-align: center;
        .mail-container{
            width: 70%;
            margin: 0 auto;
            h3{
                font-family: "Titillium-bold", sans-serif;
                size: 20px;
                margin-bottom: 20px;
            }
        }
        p{
            font-family: "Titillium-bold", sans-serif;
            size: 20px;
        }
        #icon-ready{
            img{
                width: 80px;
            }
        }
    }
    .icon_in{
        font-style: normal;
        color: white;
        background-color: #3c424e;
        display: block;
        border-radius: 100px;
        background-size: auto 30px;
        margin: 20px 130px;
        width: 130px;
        height: 130px;
        position: relative;
        padding: 21px 0 0 23px;
        font-family: "Titillium-bold", sans-serif;
        font-size: 3em;
        background-repeat: no-repeat;
        background-position: center;
    }
    p{
        margin-top: 30px;
        margin-left: 10px;
    }
    #mail-form{
        #mail-field{
            width: 317px;
        }
    }

}