/* =======================================================================
## ++ Globals
========================================================================== */
html{
    font-size: 100%;
}
html,body{
  height: 100%;
}
html, html a{
  -webkit-font-smoothing: antialiased !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
body{
    -webkit-font-smoothing: antialiased;
     background-color: $background;
     padding: 0;
     color: $font;
     font-family: 'Sarala', sans-serif;
     line-height: 1.4;
     font-size: 15px;
     overflow-x: hidden;
  //-- hide any kind of overflow on mobiles
  @media screen and (max-width: 980px) {
    overflow-x: hidden;
  }
}

*{
    box-sizing:border-box;
}

a{
  transition:$transition;
  cursor: pointer;
  color: $link;
}
a:hover{
  color: #000;
}

a, button, button:focus, a:focus, input:focus {
  outline: 0;
}
h1,h2,h3,h4,h5{
  font-family: 'Titillium-bold', sans-serif;
  word-break: break-word;
  line-height: 1.4;
  @media only screen and (max-width : 667px) {
    line-height: 1.25;
  }
}
input, button, textarea{
  font-size: 14px;
  font-family: 'Sarala', sans-serif;
}

input::-moz-placeholder {
  color: #ccc;
}
input:-ms-input-placeholder {
  color: #ccc;
}
input::-webkit-input-placeholder {
  color: #ccc;
}

input:focus::-moz-placeholder {
  transition: opacity 0.25s 0.03s ease;
  opacity: 0;
}
input:focus:-ms-input-placeholder {
  transition: opacity 0.25s 0.03s ease;
  opacity: 0; }
input:focus::-webkit-input-placeholder {
  transition: opacity 0.25s 0.03s ease;
  opacity: 0; }

button::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: none;
}
a:focus {
  outline: none;
}

h1 {
  font-size: 2em;
  padding: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  padding: 0.5em 0;
}

h3 {
  font-size: 1.35em;
  padding: 0.5em 0;
}

h4 {
  font-size: 1.25em;
  padding: 0.4em 0;
}

h5 {
  font-size: 1.2em;
  padding: 0.3em 0;
}

i, cite, em{
  font-style: italic;
}
strong, b{
  font-family: 'Sarala-bold', sans-serif;
}

/* =======================================================================
## ++ Cleafix
========================================================================== */

/* float clearing for IE6 */
* html .clearfix{
 height: 1%;
 overflow: visible;
}

/* float clearing for IE7 */
*+html .clearfix{
 min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after{
 clear: both;
 content: ".";
 display: block;
 height: 0;
 visibility: hidden;
 font-size: 0;
}

.clr{clear:both;}


::-moz-selection { background: $font; color: white; }
::selection { background: $font; color: white; }