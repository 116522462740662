//--PART2
#shopping_cart{
  //margin-top: 30px;
}
._part1{
  width: 50%;
  ul{
    li{
      vertical-align: top;
    }
  }
  p{
    padding-right: 5px;
  }
}
._part2{
  width: 48%;
  float: right;
}
._part {
  position: relative;
  border: 1px solid rgba(0,0,0,0.1);
  display: inline-block;
  vertical-align: top;
  background-color: whitesmoke;
  padding: 0 20px 15px 20px;
  margin-bottom: 20px;
  min-height: 125px;
  p{
    margin: 2px 0;
    font-size: 15px;
    word-break: break-word;
  }
  h3{
    font-size: 1em;
    text-transform: uppercase;
    padding: 0;
    font-family: 'Sarala-bold', sans-serif;
  }
  h2{
    background: #3c424e;
    color: white;
    margin: 0 -21px 15px;
    padding: 0.5em 0 0.5em 20px;
    font-size: 1.35em;
  }
  ._note{
    font-size: 13px;
    padding-left: 12px;
    display: table-cell;
    vertical-align: middle;
  }
  .payment-logo {
    background-color: #fff;
    border:1px solid rgba(0,0,0,0.1);
    width: 105px;
    height: 45px;
    position: relative;
    padding: 0 5px;
    border-radius: 5px;
    float: left;
    svg{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
  .double_card{
    width: 115px;
    svg{
      width: 50px;
      &:first-of-type{
        left: 4px;
        right: auto;
      }
      &:last-of-type{
        left: auto;
        right: 4px;
      }
    }
  }
}
.btn-aendern{
  margin: 0;
  display: table;
  background: $color;
  color: #fff;
  padding: 4px 15px 4px 20px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 4px;
  text-decoration: none;
  position: absolute;
  right: 20px;
  top: 10px;
  border: 1px solid $font;
  z-index: 0;
  overflow: hidden;
    svg{
      fill: #fff;
      margin-left: 5px;
      width: 9px;
      height: 9px;
      transition: $transition;
    }
    &::after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #fff;
      transform-origin: left;
      transition: width 0.35s ease;
      z-index: -1;
    }
    &:hover{
      color: $font;
        svg{
          fill:$font;
        }
        &::after{
          width: 100%;
        }
    }
}

.zuruck-block{
  position: absolute;
  bottom: 20px;
  left: 20px;
  @media only screen and (max-width: 980px){
    display: none;
    position: inherit;
    bottom: 0;
    left: 0;
  }
}

@media only screen and (max-width: 1400px){
  ._part1 {
    width: 60%;
  }
  ._part2{
    width: 38%;
  }
}
@media only screen and (max-width: 980px){
  ._part1,  ._part2{
    width: 100%;
  }
}

@media only screen and (max-width: 667px){
  ._part {
    padding: 0 10px 10px 10px;
    h2 {
      font-size: 1.2em;
      line-height: 1.75;
      margin: 0 -11px 10px;
      padding: 0.5em 0 0.5em 10px;
    }
  }
  ._part1 {
    width: 100%;
    ul{
      li{
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 440px){
  ._part{
    .payment-logo, ._note{
      display: block;
      margin: 0 auto;
      float: none;
    }
    ._note{
      padding-left: 0;
      padding-top: 5px;
    }
  }
}