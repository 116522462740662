

//--LEXIKON TARGET SINGLE PAGE
.lexikon-target-single{
  .aehnliche-vorlagen{
    margin-top: 50px;
    @media only screen and (max-width : 1024px) {
      margin-top: 20px;
    }
  }
  ul.pager{
    border-top: 1px solid #ccc;
    padding:20px 0 0;
    li{
      width: 50%;
      display: inline-block;
      &:last-of-type{
        text-align: right;
        float: right;
      }
      a{
        display: table;
        border-radius: 30px;
        border: 1px solid $font;
        text-decoration: none;
        color: $font;
        font-size: 14px;
        line-height: 1.2;
        span{
          display: table-cell;
          vertical-align: middle;
        }
         svg{
           display: table-cell;
          fill: $color;
          width: 18px;
          vertical-align: middle;
          transition: $transition;
        }
        &:hover{
          background-color: $font;
          color: white;
          svg{
            fill: #fff;
          }
        }
      }
      @media only screen and (max-width : 667px) {
        width:100%;
        float: none !important;
        text-align: center;
        margin: 5px auto ;
        a{
          display: table;
          margin: 0 auto !important;
        }
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    .next{
      text-align: right;
      a{
        padding: 4px 15px 4px 25px;
        margin: 0 0 0 auto;
        svg{
          margin: 0 0 0 8px;
        }
      }
    }
    .previous {
      a {
        padding: 4px 25px 4px 15px;
        svg {
          margin: 0 8px 0 0;
        }
      }
    }
  }
  .right-sidebar{
    @media only screen and (max-width: 1024px){
      width: 100%;
      margin: 20px auto 0;
      display: block;
      float: none;
    }
  }
}
//--LEXIKON TARGET PAGE, LEXIKON TARGET SINGLE PAGE
.lexikon-target {
  .lexikon-intro{
    background-color: #fff;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    padding-bottom: 30px;
    @media only screen and (max-width : 1024px) {
      margin-bottom: 20px;
    }
  }
}
.lexikon-target-single{
  .nav-target{
    margin-bottom: 10px;
  }
  .right-page-box{
    float: left;
    margin-top: 15px;
    h1{
      padding-top: 0 !important;
    }
    @media only screen and (max-width: 667px){
      margin-top: 0;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
}
#lexikon, .lexikon-target, .lexikon-target-single {
  .breadcrumb {
    @media only screen and (max-width: 667px) {
      display: none;
    }
  }
}
.lexikon-target, .lexikon-target-single{
  .breadcrumb {
    @media only screen and (max-width: 667px) {
      display: none;
    }
  }
  .lex-desc{
    line-height: 1.6;
    padding: 20px 20px 10px 0;
    @media only screen and (max-width : 1024px) {
      padding: 10px 0 5px 0;
    }
  }
  .nav-target{
    margin-top: 0;
    .current-page{
      background-color: #fff;
      svg{
        fill: grey;
      }
      h2{
        color: $font;
      }
    }
  }
  h1 {
    padding: 5px 0;
  }
  article{
    vertical-align: top;
    margin: 20px auto 30px auto;
    display: block;
    width: 90%;
    padding: 20px 3% 30px;
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: $box-shadow;
    position: relative;
    @media only screen and (max-width : 1200px) {
      width: 99%;
      margin: 20px 0 30px 1%;
    }
    @media only screen and (max-width : 1024px) {
      margin: 0 0 20px 1%;
    }
    h2{
      position: absolute;
      font-size: 2em;
      padding: 5px 30px;
      font-weight: 700;
      background: $color;
      display: block;
      margin: 0;
      top: -10px;
      left: -10px;
      color: white;
    }
    ul{
      margin-top: 40px;
      width: 100%;
      li{
        float: left;
        text-align: left;
        width: 50%;
        padding:4px 20px 4px 0;
         a{
          font-size: 18px;
          text-decoration: none;
         }
        @media only screen and (max-width : 667px) {
          width: 100%;
          padding: 2px 10px 2px 0;
          a{
            font-size: 15px;
          }
        }
      }
    }
  }
}
.alfabet-menu{
  margin: 10px 0 0 0;
  display: inline-block;
  span{
    margin-right: 20px;
  }
  a{
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
    color: $font;
    padding:1px 7px;
    margin: 5px 4px 5px 0;
    border: 1px solid $color;
    z-index: 1;
    position: relative;
    @media only screen and (max-width : 1400px) {
      font-size: 14px;
    }
    &::after{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $color;
      transform-origin: left center 0;
      z-index: -1;
      transition: width 0.35s ease;
    }
    &:hover::after{
      width: 100%;
    }
    &:hover{
      color: #fff;
    }
  }
}

//--LEXIKON PAGE
#lexikon{
  h1{
    padding: 15px 0 0 0;
  }
  .menu-header {
    margin-bottom: 0;
  }
}
.lexikon-intro{
  background-color: #fff;
  box-shadow: $box-shadow;
  @media only screen and (max-width : 1024px) {
    padding-bottom: 10px;
  }
  p{
    margin: 15px 0;
  }
  .lex-nav.stick{
    position: fixed;
    top: 0;
    margin:0;
    z-index: 11;
  }
}
.lex-nav{
  margin-top: 30px;
  border-top: 1px solid white;
  background-color: $font;
  width: 100%;
  display: table;
  li{
    border-right: 1px solid white;
    width: 12.5%;
    float: left;
    position: relative;
    height: 90px;
    transition: $transition;
    a{
      padding-top: 15px;
      display: block;
      height: 90px;
    }
    a.nav-active{
      background-color: white;
      svg{
        fill:  grey;
      }
      h2{
        color: $font;
      }
    }
    svg{
      fill: #fff;
      width: 42px;
      height: 42px;
      display: block;
      margin:0 auto;
    }
    h2{
      padding: 0;
      position: absolute;
      bottom: 7px;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      margin: auto;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      font-family: "Titillium-bold", sans-serif;
    }
    &:hover{
      background-color: #fff;
    }
    &:hover svg{
      fill: grey;
    }
    &:hover h2{
      color: $font;
    }
    &:first-of-type{
      border-left: 1px solid white;
    }
    @media only screen and (max-width : 1200px) {
      h2{
        font-size: 11px;
      }
    }
  }
  @media only screen and (max-width : 1024px) {
    display: none;
  }
}

.lexikon-box.padd{
  margin-top: 150px;
}
.lexikon-box {
  background-color: #fff;
  box-shadow: $box-shadow;
  margin: 30px 0 0 0;
  padding: 50px 0;
  width: 100%;
  height: 100%;
  ul {
    li {
      margin: 0 auto;
      width: 90%;
      border-bottom: 1px solid #ddd;
      padding: 20px 0 25px 4%;
      &:last-of-type {
        border-bottom: 0;
      }
      a{
        display: block;
        text-decoration: none;
        color: $font;
      }
      &:hover {
        background: white;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.35);
      }
      &:hover .btn::after {
        width: 100%;
      }
      h2 {
        padding-top: 20px;
        padding-left: 20px;
        vertical-align: top;
        display: inline-block;
      }
      .lex-desc {
        margin: 0;
        display: table-cell;
        padding: 0 40px 0 0;
        line-height: 1.6;
        border-right: 1px solid #aaa;
      }
      .zl {
        vertical-align: middle;
        margin: 0;
        display: table-cell;
        padding: 0 20px 0 0;
        width: 300px;
        text-align: center;
      }
    }
  }
}

.svg-holder {
  width: 80px;
  height: 80px;
  background-color: $font;
  border: 5px solid $color;
  border-radius: 100px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
  div{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-size: 40px;
    width: 40px;
    height: 40px;
    display: block;
  }
  .leximg-1{
    background:url('images/svg/lexikon/existenz.svg') center no-repeat;
  }
  .leximg-2{
    background:url('images/svg/lexikon/controlling.svg') center no-repeat;
  }
  .leximg-3{
    background:url('images/svg/lexikon/marketing.svg') center no-repeat;
  }
  .leximg-4{
    background:url('images/svg/lexikon/rechnung.svg') center no-repeat;
  }
  .leximg-5{
    background:url('images/svg/lexikon/organisation.svg') center no-repeat;
  }
  .leximg-6{
    background:url('images/svg/lexikon/bewerbung.svg') center no-repeat;
  }
  .leximg-7{
    background:url('images/svg/lexikon/finanzen.svg') center no-repeat;
  }
  .leximg-8{
    background:url('images/svg/lexikon/arbeitgeber.svg') center no-repeat;
  }
}

.top-lines-left{
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  &::before{
    content: "";
    width: 200px;
    height: 2px;
    background-color: $color;
    position: absolute;
    top: -10px;
    left: 2%;
  }
  &::after{
    content: "";
    width: 2px;
    height: 170px;
    background-color: $color;
    position: absolute;
    top: -30px;
    left: 4%;
  }
}
.top-lines-right{
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  &::before{
    content: "";
    width: 200px;
    height: 2px;
    background-color: $color;
    position: absolute;
    top: -10px;
    right: 2%;
  }
  &::after{
    content: "";
    width: 2px;
    height: 170px;
    background-color: $color;
    position: absolute;
    top: -30px;
    right: 4%;
  }
}
.bottom-lines-right{
  height: 100%;
  width: 100%;
  position: relative;
  &::before{
    content: "";
    width: 200px;
    height: 2px;
    background-color: $color;
    position: absolute;
    bottom: -10px;
    right: 2%;
  }
  &::after{
    content: "";
    width: 2px;
    height: 170px;
    background-color: $color;
    position: absolute;
    bottom: -30px;
    right: 4%;
  }
}
.bottom-lines-left{
  height: 100%;
  width: 100%;
  position: relative;
  &::before{
    content: "";
    width: 200px;
    height: 2px;
    background-color: $color;
    position: absolute;
    bottom: -10px;
    left: 2%;
  }
  &::after{
    content: "";
    width: 2px;
    height: 170px;
    background-color: $color;
    position: absolute;
    bottom: -30px;
   left: 4%;
  }
}
//--MAKE THIS PAGE RESPONSIVE


@media only screen and (max-width : 1024px) {
  .lexikon-box ul li{
    .zl {
    width: 240px;
    }
    .lex-desc {
      padding: 0 20px 0 0;
    }
  }
}

  .lexikon-box{
    //--footer margins
    @media only screen and (max-width : 800px) {
    margin-bottom: -90px;
    }
    @media only screen and (max-width : 667px) {
      margin-bottom: -35px;
    }
    @media only screen and (max-width : 460px) {
      margin-bottom: -20px;
    }
  }
@media only screen and (max-width : 800px) {
  .top-lines-right, .bottom-lines-left{
    display: none;
   }
  .lexikon-box{
    width: 104%;
    margin-left: -2%;
    margin-top: 0;
    box-shadow: none;
    padding-top: 25px;
    ul li{
      h2{
        padding-left: 10px;
        font-size: 1.35em;
      }
    .zl {
      display: table;
      width: 100%;
    }
    .lex-desc {
      padding: 0;
      border-right:0;
    }
  }
  }
}


//--MIXED
#lexikon{
  @media only screen and (max-width : 667px) {
    h1 {
      padding-top: 16px;
      font-size: 1.5em;
    }
  }
}