$green: #579400 !default;

div.newsletter{
	.page-content{
		background-repeat: no-repeat; 
		background-size: 100% auto;
		
		padding-top: 30px; 
		text-align: right;
		
		@media only screen and (max-width : 1000px) {
			background-size: auto 100%;
		}
	}
	.page-content.step2{
		background-image: url(/images/fond_newsletter_2.jpg); 
	}
	.page-content.step1{
		background-image: url(/images/fond_newsletter.jpg);
	}
	#legend{
		text-align: right;
		margin: 5px 12% 0 0;
		font-size: 11px;
		color: #666666;
	}
	@media only screen and (max-width : 350px) {
		input[type="text"], input[type="email"], select{
		  width: 90%;
		}
		label{
			margin-left: 17px !important;
		}
	}
	@media only screen and (min-width : 350px) {
		input[type="text"], input[type="button"], input[type="email"], select{
		  width: 53%;
		}
	}
	a{
		color: $green;
	}
	h1{
		color: $green;
		@media only screen and (max-width : 650px) {
			font-size: 1.5em;
		}
	}
	h3{
		margin-bottom: 6%; 
		padding-left: 20%; 
		padding-right: 20%
	}
	label{
		float: left; 
		width: 120px; 
		margin-left: 55px;
		text-align: left;
	}
	button{
		margin-bottom: 10%; 
		width: 48%; 
		height: 50px; 
		margin-left: 20px; 
		margin-right: 20px; 
		background-color: $green; 
		border: none; 
		color: white;  
		font-weight: bold;
		cursor: pointer;
	}
	select{
		height: 30px;
	}
	.radios{
		/*float: left;*/
		margin-left: 55px;
		text-align: left;
		max-width: 730px;
	}

	.radios span{
		white-space: nowrap;
	}
	.grey-content-box{
		background-color: rgba(238, 238, 238, 0.8);  
		padding-top: 15px; 
		max-width: 730px; 
		display: inline-block; 
		text-align: center;
	}
	#confirm-form div, #newsletter-form div{
		margin-top: 10px;
	}
	.content-newsletter ul{
	  border: 2px solid #579400;
	  padding: 1% !important;
	  margin-left: 10% !important;
	  margin-right: 10% !important;
	}
	.content-newsletter ul li{
	  display: inline;
	  margin-right: 2%;
	}
	.hooklist{
		border: 2px solid #579400; 
		padding: 1%; 
		margin: 10%;
		li{
			display: inline;
			margin-right: 2%;
		}
	}
	.spacer30{
		width: 100%;
		margin-top: 30px;
	}
	.spacer50{
		width: 100%;
		margin-top: 50px;
	}
}
